import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  Checkbox,
} from "@material-ui/core";
import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteLocalidad,
  getLocalidadById,
  postLocalidad,
  updateLocalidad,
} from "../../../../api/localidad";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getProvincias } from "../../../../api/provincia";
import { shallowEqual, useSelector } from "react-redux";
import { postLog } from "../../../../api/log";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyLocalidad() {
  return {
    fullName: "",
    provincia: "",
    code: "",
    takeIntoAccount: false,
    checkStreet: true,
  };
}

export default function EditLocalidadesPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [localidad, setLocalidad] = useState(getEmptyLocalidad());
  const [provincias, setProvincias] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const localidadId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    getProvincias()
      .then((res) => {
        if (res.status === 200) {
          setProvincias(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se han podido obtener las provincias.",
        });
        history.push("/localidades");
      });
    if (!localidadId) {
      disableLoadingData();
      return;
    }
    getLocalidadById(localidadId)
      .then((res) => {
        if (res.status === 200) {
          const user = res.data;
          delete user.password;
          setLocalidad(user);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se ha podido obtener localidad.",
        });
        history.push("/localidades");
      });
  }, [localidadId, disableLoadingData, history]);

  function saveLocalidad() {
    if (!localidadId) {
      postLocalidad(localidad)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Localidad creada correctamente.",
            });
            postLog("Localidades", `create-${localidadId}`, user.fullName);
            history.push("/localidades");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se ha podido guardar localidad.",
          });
        });
    } else {
      updateLocalidad(localidadId, localidad)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Los cambios se han guardado correctamente.",
            });
            postLog("Localidades", `update-${localidadId}`, user.fullName);
            history.push("/localidades");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se han podido guardar los cambios.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    if (element === "provincia") {
      const code = provincias.find((item) => item._id === event._id).code;
      setLocalidad({ ...localidad, [element]: event._id, code: code + "." });
    } else setLocalidad({ ...localidad, [element]: event.target.value });
  };

  useEffect(() => {
    console.log("TAKE INTO ACCOUNT: ", localidad.takeIntoAccount);
  }, [localidad]);

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Editar localidad"></CardHeader>
          <CardBody>
            <TextField
              id={`fullName`}
              label="Localidad"
              value={localidad.fullName}
              onChange={handleChange("fullName")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <span>Província</span>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              className="my-2"
              defaultValue={
                localidad.provincia && provincias
                  ? provincias.find((item) => item._id === localidad.provincia)
                  : ""
              }
              onChange={handleChange("provincia")}
              options={provincias}
              getOptionLabel={(option) => `${option.fullName}`}
              getOptionValue={(option) => `${option._id}`}
              placeholder="Seleccione una provincia..."
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
            <br />
            <label>
              Check para aquellas poblaciones que en caso que no se encuentren
              suficientes resultados a la hora de buscar posibles direcciones,
              se proceda a realizar una búsqueda más general
            </label>
            <br />
            <b>Se recomienda marcarlo para poblaciones pequeñas</b>
            <Checkbox
              checked={localidad?.checkStreet ? true : false}
              onChange={() =>
                setLocalidad((prevValue) => ({
                  ...prevValue,
                  checkStreet: !prevValue.checkStreet,
                }))
              }
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <br />
            <b>
              Check para poblaciones que se quieren tener en cuenta en el
              proceso de identificación de direcciones
            </b>
            <Checkbox
              checked={
                localidad?.takeIntoAccount === false ||
                !localidad?.takeIntoAccount
                  ? false
                  : true
              }
              onChange={() =>
                setLocalidad((prevValue) => ({
                  ...prevValue,
                  takeIntoAccount: !prevValue.takeIntoAccount,
                }))
              }
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/localidades")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Atrás
        </Button>
        <Button
          onClick={() => saveLocalidad()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Guardar localidad
        </Button>
        {localidadId && user?.role === "admin" && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Eliminar localidad
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"¿Estás seguro de que quieres eliminar esta localidad?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteLocalidad(localidadId)
                  .then((res) => {
                    if (res.status === 204) {
                      alertSuccess({
                        title: "¡Eliminado!",
                        customMessage: "Localidad eliminada correctamente",
                      });
                      postLog(
                        "Localidades",
                        `delete-${localidadId}`,
                        user.fullName
                      );
                      history.push("/localidades");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "No se ha podido eliminar la localidad.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
