import * as XLSX from "xlsx";

export const exportPropertiesToExcel = (properties) => {
  const data = properties.map((property) => {
    const priceObject = property?.price?.[property.price.length - 1] || {}; // Get the latest price or an empty object
    const priceValue = priceObject?.value || "N/A"; // If value doesn't exist, default to "N/A"

    return {
      Title: property.title || "N/A",
      Type: property.type || "N/A",
      Price: priceValue,
      Area: property.area || 0,
      Bathrooms: property.nbaños || 0,
      Bedrooms: property.nhabitaciones || 0,
      Floors: property.nplanta || 0,
      Year: property.añoConstruccion || "N/A",
      Status: property.status || "N/A",
      Addresses: property.addresses?.join(", ") || "N/A",
      "In Idealista": property.isInIdealista ? "Yes" : "No",
      "Potential Duplicates": property.potentialDuplicates?.length || 0, // Number of potential duplicates
      "Created At": property.createdAt
        ? new Date(property.createdAt).toLocaleString()
        : "N/A",
      Seen: property.seen ? "Yes" : "No",
      Active: property.active ? "Yes" : "No",
      "Cadastral References":
        property.possibleCadastralReferences?.join(", ") || "N/A",
      ID: property.id, // Property ID
    };
  });

  // Create a worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Create a workbook
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Properties");

  // Export the file
  XLSX.writeFile(workbook, "properties.xlsx");
};
