import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Button,
  TextField,
  MuiThemeProvider,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Tooltip as MuiTooltip,
  IconButton,
  Grid,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Typography,
  Box,
  CardContent,
  Modal,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Checkbox,
} from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { useHistory, useParams } from "react-router-dom";
import { formatDate } from "@fullcalendar/core/index.js";
import {
  deleteProperty,
  getPropertyById,
  postProperty,
  updateProperty,
  syncIndividualExtraPic,
  getGroupCadastralReferences,
  syncIndividualAddresses,
} from "../../../../api/property";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess, alertInfo } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import {
  ArrowDownward,
  ArrowUpward,
  Visibility,
  Loop,
  OpenInNew,
  Delete as DeleteIcon,
  OpenInNew as OpenInNewIcon,
  CheckCircle as CheckCircleIcon,
} from "@material-ui/icons";
import { useSelector } from "react-redux";
import { getLocalidades } from "../../../../api/localidad";
import { SERVER_URL } from "../../../../api/index";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { buttonsStyle } from "../../../components/tables/table";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { postLog } from "../../../../api/log";
import { getEstadosPipeline } from "../../../../api/estadosPipeline";
import { getUsers } from "../../../../api/user";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
  },
});

const PropertyDashboard = ({ property, initialColumns }) => {
  const [columns, setColumns] = useState(initialColumns);

  useEffect(() => {
    if (property.status != "") {
      const columnToInsert = columns[property.status];
      if (columnToInsert) {
        columnToInsert.items.push({
          id: property.title,
          content: property.title,
        });
      }
      setColumns({
        ...columns,
        [property.status]: {
          ...columnToInsert,
          items: columnToInsert.items,
        },
      });
    }
  }, []);

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    property.status = destination.droppableId;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  return (
    <DragDropContext
      onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "flex-start",
        }}
      >
        {columns &&
          Object.entries(columns).map(([columnId, column], index) => {
            return (
              <div
                key={columnId}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "20%",
                  marginHorizontal: 10,
                  backgroundColor: column.color,
                }}
              >
                <h2>{column.name}</h2>
                <Droppable droppableId={columnId}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        background: snapshot.isDraggingOver
                          ? "lightblue"
                          : "white",
                        padding: 4,
                        width: "100%",
                        minHeight: 300,
                        borderRadius: 4,
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      }}
                    >
                      {column.items.map((item, index) => (
                        <Draggable
                          key={item?.id ? item.id : index.toString()}
                          draggableId={item?.id ? item.id : index.toString()}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Card
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                userSelect: "none",
                                padding: 16,
                                margin: "0 0 8px 0",
                                minHeight: "50px",
                                backgroundColor: snapshot.isDragging
                                  ? "#F6F9FE"
                                  : "#EEF0F4",
                                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                ...provided.draggableProps.style,
                              }}
                            >
                              <span>
                                {property.gallery.length > 0 &&
                                property.gallery[0] ? (
                                  <img
                                    src={`${property.gallery[0]}`}
                                    height={100}
                                  />
                                ) : null}
                              </span>
                              <br />
                              {item.content
                                ? item.content
                                : "Estado de la vivienda"}
                            </Card>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            );
          })}
      </div>
    </DragDropContext>
  );
};

function getEmptyProperty() {
  return {
    title: "",
    description: "",
    gallery: [],
    type: "",
    localidad: "",
    addresses: [],
    validAddresses: [],
    moreInfo: "",
    certificadoEnergetico: "",
    phone: "",
    origin: "",
    price: [],
    area: 0,
    status: "663375d8559168254439ca03",
    evolution: [],
    comentarios: [],
    assignedTo: "",
    seen: false,
    active: true,
  };
}

const PriceChart = ({ priceData }) => {
  const data = priceData.map((item) => ({
    date: new Date(item.date).toLocaleDateString("en-US"),
    value: item.value,
  }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="value"
          stroke="#FF7F50"
          strokeWidth={2}
          activeDot={{ r: 8 }}
          dot={{ stroke: "#FF4500", strokeWidth: 2, fill: "#FFA500" }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default function EditPropertiesPage() {
  //Selected property
  const [property, setProperty] = useState(getEmptyProperty());

  //Catatral references
  const [references, setReferences] = useState([]);
  const [selectedReference, setSelectedReference] = useState(null);

  //localities
  const [localidades, setLocalidades] = useState([]);

  //Images gallery
  const [gallery, setGallery] = useState([]);

  //Image modals and dialogs
  const [openModal, setOpenModal] = useState(false);
  const [openPreviewDialogImage, setOpenPreviewDialogImage] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openNewDataModal, setOpenNewDataModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);

  //Pipeline states of the property
  const [estadosPipeline, setEstadosPipeline] = useState([]);
  //Initial columns for the drag and drop
  const [initialColumns, setInitialColumns] = useState({});

  //Workers to assign the property
  const [users, setUsers] = useState([]);

  //Array of price evolution of the property
  const [price, setPrice] = useState();

  //Comments
  const [newComment, setNewComment] = useState("");

  //New address data
  const [newAddress, setNewAddress] = useState("");

  //Valid addresses
  const [validAddresses, setValidAddresses] = useState(
    property.validAddresses || []
  );

  //New data for updating addresses
  const [newData, setNewData] = useState({
    localidad: "",
    calle: "",
    area: "",
    errorArea: "",
    año: "",
    errorAño: "",
    primerNumeroPolicia: "",
    segundoNumeroPolicia: "",
    escalera: "",
    planta: "",
    puerta: "",
  });
  const propertyId = useParams().id;
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user);

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!propertyId) {
      disableLoadingData();
      return;
    }
    getPropertyById(propertyId)
      .then((res) => {
        if (res.status === 200) {
          const filteredEvolution = res.data.evolution.filter((ev) => {
            return ev.status != null;
          });
          res.data.evolution = filteredEvolution;
          setProperty(res.data);
          setGallery(res.data.gallery);
          setPrice(res.data.price[res.data.price.length - 1].value);
          setValidAddresses(res.data.validAddresses);
          if (res.data.possibleCadastralReferences.length > 0) {
            getGroupCadastralReferences(res.data.possibleCadastralReferences)
              .then((res) => {
                if (res.status === 200) {
                  setReferences(res.data);
                }
              })
              .catch((error) => {
                alertError({
                  error: error,
                  customMessage: "Could not load possibleCadastralReferences.",
                });
              });
          }
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not load property.",
        });
      });
  }, [propertyId, disableLoadingData, history]);

  useEffect(() => {
    getLocalidades()
      .then((res) => {
        if (res.status === 200) {
          setLocalidades(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not load localidades.",
        });
      });

    getUsers()
      .then((res) => {
        if (res.status === 200) {
          setUsers(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not load users.",
        });
      });

    getEstadosPipeline()
      .then((res) => {
        if (res.status === 200) {
          setEstadosPipeline(res.data.sort((a, b) => a.order - b.order));
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not load estados pipeline.",
        });
      });
  }, []);

  useEffect(() => {
    if (Object.keys(initialColumns).length === 0) {
      getEstadosPipeline().then((res) => {
        if (res.status === 200) {
          const columns = {};
          const orderedData = res.data.sort((a, b) => a.order - b.order);
          orderedData.forEach((column) => {
            columns[column._id] = {
              name: column.name,
              items: [],
              color: column.color,
            };
          });
          setInitialColumns(columns);
        }
      });
    }
    if (!property.seen && user._id === property.assignedUser)
      updateProperty(propertyId, { seen: true });
  }, [property]);

  const getUserNameById = (userId) => {
    const user = users.find((user) => user._id === userId);
    return user ? user.fullName : "Unknown User";
  };

  // Manage different comments
  const addComment = () => {
    if (!newComment.trim()) return;
    const commentToAdd = {
      message: newComment,
      user: user._id,
      createdAt: new Date(),
    };
    setProperty({
      ...property,
      comentarios: [...property?.comentarios, commentToAdd],
    });
    setNewComment("");
  };

  const deleteComment = (commentIndex) => {
    const isConfirmed = window.confirm(
      "¿Estás seguro de que quieres eliminar el siguiente comentario?"
    );

    if (isConfirmed) {
      setProperty({
        ...property,
        comentarios: property?.comentarios.filter(
          (_, index) => index !== commentIndex
        ),
      });
    }
  };

  // Modifying and saving property functions
  function saveProperty() {
    let saveProperty = property;
    if (!propertyId) {
      postProperty(saveProperty, gallery)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Property successfully created.",
            });
            history.push("/properties");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save property.",
          });
        });
    } else {
      updateProperty(propertyId, saveProperty, gallery)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            history.push("/properties");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const syncPropertyImages = async () => {
    setIsLoading(true);
    const res = await syncIndividualExtraPic(propertyId);
    setIsLoading(false);
    if (res.status === 200) {
      alertSuccess({
        title: "Images Synced!",
        customMessage: "Imágenes sincronizadas correctamente",
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      window.location.reload();
    } else {
      alertError({
        error: res,
        customMessage: "No se han podido sincronizar las imágenes",
      });
    }
  };

  const handleChange = (element, index, subcategoryKey) => (event) => {
    setProperty({ ...property, [element]: event.target.value });
  };

  const handleAddPrice = () => {
    property.price.push({ value: price, date: new Date() });
  };

  const renderGallery = () => {
    return (
      <div>
        <label htmlFor="upload-gallery">
          <input
            style={{ display: "none" }}
            id="upload-gallery"
            name="upload-gallery"
            type="file"
            accept="image/*"
            onChange={(e) => {
              const files = e.target.files;
              if (files.length > 0) {
                setGallery([...gallery, ...files]);
              }
            }}
            multiple
          />
          <Button color="secondary" component="span" variant="outlined">
            Upload images
          </Button>
          <IconButton onClick={() => syncPropertyImages()}>
            <Loop />
            <span style={{ fontSize: "12px" }}>Sincronizar imágenes</span>
          </IconButton>
          {isLoading && (
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="spinner-border text-primary m-5 align-self-center"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </label>
        <Grid container spacing={2} alignItems="center" justify="center">
          {gallery.map((file, index) => (
            <React.Fragment key={index}>
              <Grid
                item
                xs={12}
                sm={3}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src={
                    file instanceof File ? URL.createObjectURL(file) : `${file}`
                  }
                  alt={`Preview ${index + 1}`}
                  style={{
                    width: "60px",
                    height: "60px",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  onClick={() => setOpenPreviewDialogImage(index)}
                />
                <section className="d-flex flex-row align-items-center">
                  <PreviewDialog
                    title={"Preview Image" + (index + 1)}
                    open={openPreviewDialogImage === index}
                    setOpen={setOpenPreviewDialogImage}
                    src={
                      gallery && gallery[index]
                        ? gallery[index] instanceof File
                          ? URL.createObjectURL(gallery[index])
                          : `${gallery[index]}`
                        : `${property.gallery[index]}`
                    }
                  />
                  <MuiTooltip title="Delete">
                    <Button
                      onClick={() => {
                        const isConfirmed = window.confirm(
                          "Are you sure you want to delete this image?"
                        );
                        if (isConfirmed) {
                          const newGallery = gallery.filter(
                            (_, i) => i !== index
                          );
                          setGallery(newGallery);
                        }
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </MuiTooltip>
                  <MuiTooltip title="Move Up">
                    <span>
                      <Button
                        disabled={index === 0}
                        onClick={() => handleMoveImage(index, index - 1)}
                      >
                        <ArrowUpward />
                      </Button>
                    </span>
                  </MuiTooltip>
                  <MuiTooltip title="Move Down">
                    <span>
                      <Button
                        disabled={index === gallery.length - 1}
                        onClick={() => handleMoveImage(index, index + 1)}
                      >
                        <ArrowDownward />
                      </Button>
                    </span>
                  </MuiTooltip>
                </section>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </div>
    );
  };

  const handleMoveImage = (index, newIndex) => {
    const images = [...gallery];
    const aux = images[index];
    images[index] = images[newIndex];
    images[newIndex] = aux;
    setGallery(images);
    setProperty({ ...property, gallery: images });
  };

  const renderCadastralReference = () => {
    if (!selectedReference) {
      return (
        <MuiCard>
          <MuiCardHeader title="Información del Catastro" />
          <CardContent>
            <Typography>
              No hay información cadastral de la dirección seleccionada
            </Typography>
          </CardContent>
        </MuiCard>
      );
    }

    return (
      <MuiCard>
        <MuiCardHeader title="Información del Catastro" />
        <CardContent>
          <Grid container spacing={3}>
            {Object.entries(selectedReference).map(([key, value]) => {
              if (
                typeof value === "object" &&
                value !== null &&
                !Array.isArray(value)
              ) {
                return (
                  <Grid item xs={12} sm={6} key={key}>
                    <Box mb={2}>
                      <Typography variant="h6" gutterBottom>
                        {key}
                      </Typography>
                      {Object.entries(value).map(([subKey, subValue]) => (
                        <Typography key={subKey}>
                          <strong>{subKey}:</strong>{" "}
                          {subValue.length > 0 ? subValue : "No disponible"}
                        </Typography>
                      ))}
                    </Box>
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} sm={6} key={key}>
                    <Typography>
                      <strong>{key}:</strong>{" "}
                      {value.length > 0 ? value : "No disponible"}
                    </Typography>
                  </Grid>
                );
              }
            })}
          </Grid>
        </CardContent>
      </MuiCard>
    );
  };

  const handleUpdateAddresses = () => {
    alertInfo({ message: "Updating addresses..." });
    setOpenNewDataModal(false);

    // Localidad is required
    if (!newData.localidad) {
      alertError({
        customMessage: "Localidad y año son campos requeridos",
      });
      return;
    }

    syncIndividualAddresses(propertyId, newData)
      .then((res) => {
        if (res.status === 200) {
          alertSuccess({
            title: "Updated!",
            customMessage: "Addresses successfully updated.",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not update addresses.",
        });
      });
  };

  // Function to handle marking an address as valid
  const handleToggleValidAddress = (address) => {
    let updatedValidAddresses;
    if (validAddresses.includes(address)) {
      updatedValidAddresses = validAddresses.filter((addr) => addr !== address);
    } else {
      updatedValidAddresses = [...validAddresses, address];
    }

    setValidAddresses(updatedValidAddresses);
    setProperty({ ...property, validAddresses: updatedValidAddresses });
  };

  const handleChangeNewData = (field) => (event) => {
    setNewData({ ...newData, [field]: event.target.value });
  };

  const formatStatus = (estado) => {
    const status = estadosPipeline.find((e) => e._id === estado);
    return status ? status.name : "Unknown status";
  };

  const findReference = (address) => {
    return references.find(
      (r) =>
        address.includes(r.primerNumeroPolicia) &&
        address.includes(r.segundoNumeroPolicia) &&
        address.includes(r.escalera) &&
        address.includes(r.planta) &&
        address.includes(r.puerta)
    );
  };

  const handleAddAddress = () => {
    const updatedAddresses = [...property.addresses, newAddress];
    setProperty({ ...property, addresses: updatedAddresses });
    setNewAddress("");
    setOpenAddModal(false);
  };

  //FOR DEBUGGING IF NEEDED
  // useEffect(() => {
  //   console.log("New Data", newData);
  // }, [newData]);

  const renderAddressesTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Dirección</TableCell>
              <TableCell>Superfície elementos constructivos</TableCell>
              <TableCell>Año de antigüedad</TableCell>
              <TableCell>Valid</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {property?.addresses.map((address, index) => {
              const reference = findReference(address);
              return (
                <TableRow key={index}>
                  <TableCell>{address}</TableCell>
                  <TableCell>
                    {reference
                      ? reference.superficieElementosConstructivos
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {reference ? reference.anoAntiguedadBienInmueble : "N/A"}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={validAddresses.includes(address)}
                      onChange={() => handleToggleValidAddress(address)}
                      icon={<CheckCircleIcon />}
                      checkedIcon={<CheckCircleIcon color="primary" />}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        const updatedAddresses = [...property.addresses];
                        updatedAddresses.splice(index, 1);

                        const updatedPossibleReferences = references.filter(
                          (r) => r !== reference
                        );

                        setReferences(updatedPossibleReferences);
                        setProperty({
                          ...property,
                          addresses: updatedAddresses,
                          possibleCadastralReferences: updatedPossibleReferences.map(
                            (r) => r.referenciaCatastral
                          ),
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSelectedReference(reference);
                        setOpenModal(true);
                      }}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit property"></CardHeader>
          <CardBody>
            <TextField
              id={`title`}
              label="Título"
              value={property.title}
              onChange={handleChange("title")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <TextField
              id={`description`}
              label="Descripción"
              value={property.description}
              onChange={handleChange("description")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              multiline
              rows={10}
              required
            />
            <br />
            <TextField
              id={`moreInfo`}
              label="Información detallada"
              value={property.moreInfo}
              onChange={handleChange("moreInfo")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              multiline
              rows={2}
            />
            <br />
            <br />
            <TextField
              id={`certificadoEnergetico`}
              label="Certificado energético"
              value={property.certificadoEnergetico}
              onChange={handleChange("certificadoEnergetico")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <TextField
              id={`phone`}
              label="Teléfono de contacto"
              value={property.phone}
              onChange={handleChange("phone")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <div>
              <TextField
                id="price"
                label="Precio actual"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                variant="outlined"
                required
                type="number"
              />
              <Button onClick={handleAddPrice}>Add Price</Button>
            </div>
            <h4>Gráfica evolución de los precios</h4>
            <br />
            <Card>
              <CardContent>
                <h2>Property Price Evolution</h2>
                <PriceChart priceData={property.price} />
              </CardContent>
            </Card>

            <br />
            <TextField
              id={`area`}
              label="Superfície"
              value={property.area}
              onChange={handleChange("area")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              type="number"
            />
            <br />
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel id="type">Tipo</InputLabel>
              <Select
                labelId="type"
                id="type"
                value={property.type}
                onChange={handleChange("type")}
                label="Tipo"
                required
              >
                <MenuItem value={"Casa"}>Casa</MenuItem>
                <MenuItem value={"Adosado"}>Adosado</MenuItem>
                <MenuItem value={"Departamento"}>Apartamento</MenuItem>
                <MenuItem value={"Local"}>Local</MenuItem>
                <MenuItem value={"Terreno"}>Terreno</MenuItem>
                <MenuItem value={"Piso"}>Piso</MenuItem>
                <MenuItem value={"Ático"}>Ático</MenuItem>
                <MenuItem value={"Bajo"}>Bajo</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <Autocomplete
                id="localidad-autocomplete"
                options={localidades}
                getOptionLabel={(option) => option.fullName}
                value={
                  localidades.find((loc) => loc._id === property.localidad) ||
                  null
                }
                onChange={(event, newValue) => {
                  handleChange("localidad")({
                    target: { value: newValue ? newValue._id : "" },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Localidad"
                    variant="outlined"
                    required
                  />
                )}
              />
            </FormControl>
            <br />
            <br />
            <label className="m-2 p-2">
              <b>Direcciones:</b>
            </label>
            {renderAddressesTable()}
            <Button
              onClick={() => setOpenAddModal(true)}
              variant="outlined"
              color="primary"
              className="p-2"
            >
              Add Address
            </Button>
            <Button
              onClick={() => {
                const isConfirmed = window.confirm(
                  "Are you sure you want to delete all addresses?"
                );
                if (isConfirmed) {
                  setProperty({ ...property, addresses: [] });
                  setValidAddresses([]);
                }
              }}
              variant="outlined"
              className="p-2 m-2 text-danger"
            >
              DELETE ALL
            </Button>
            <Modal
              open={openModal}
              onClose={() => setOpenModal(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 100,
              }}
            >
              <div
                style={{
                  maxHeight: "90vh",
                  overflowY: "auto",
                  width: "80%",
                  maxWidth: "1000px",
                  zIndex: 10,
                }}
              >
                {renderCadastralReference()}
              </div>
            </Modal>
            <Modal
              open={openAddModal}
              onClose={() => setOpenAddModal(false)}
              aria-labelledby="add-address-modal-title"
              aria-describedby="add-address-modal-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 100,
              }}
            >
              <div
                style={{
                  background: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 3px 5px rgba(0, 0, 0, 0.3)",
                  zIndex: 10,
                }}
              >
                <h2 id="add-address-modal-title">Add New Address</h2>
                <TextField
                  label="Address"
                  value={newAddress}
                  onChange={(e) => setNewAddress(e.target.value)}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <Button
                  onClick={handleAddAddress}
                  variant="contained"
                  color="primary"
                  className="m-2"
                >
                  Add
                </Button>
              </div>
            </Modal>
            <br />
            <br />
            <Button
              onClick={() => setOpenNewDataModal(true)}
              variant="outlined"
              color="primary"
            >
              Update the Property Addresses with New Data
            </Button>
            <Modal
              open={openNewDataModal}
              onClose={() => setOpenNewDataModal(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 100,
              }}
            >
              <div
                style={{
                  maxHeight: "90vh",
                  overflowY: "auto",
                  width: "80%",
                  maxWidth: "1000px",
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "8px",
                }}
              >
                <h2>Update Addresses with New Data</h2>
                <br />
                <br />
                <FormControl fullWidth>
                  <Autocomplete
                    id="localidad-autocomplete"
                    options={localidades}
                    getOptionLabel={(option) => option.fullName}
                    value={
                      localidades.find(
                        (loc) => loc._id === newData.localidad
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      handleChangeNewData("localidad")({
                        target: { value: newValue ? newValue._id : "" },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Localidad"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                </FormControl>
                <b>
                  Importante, solo el nombre, si la calle es "Carrer de París"
                  pon "París"
                </b>
                <TextField
                  label="Nombre de la calle"
                  value={newData.address}
                  onChange={handleChangeNewData("calle")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <span className="d-flex flex-row ">
                  <TextField
                    label="Superficie"
                    value={newData.area}
                    onChange={handleChangeNewData("area")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="number"
                    className="mx-2"
                  />
                  <TextField
                    label="Error Superficie"
                    value={newData.errorArea}
                    onChange={handleChangeNewData("errorArea")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="number"
                    className="mx-2"
                  />
                </span>
                <span className="d-flex flex-row ">
                  <TextField
                    label="Año"
                    value={newData.year}
                    onChange={handleChangeNewData("año")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="number"
                    className="mx-2"
                  />
                  <TextField
                    label="Error Año"
                    value={newData.errorAño}
                    onChange={handleChangeNewData("errorAño")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="number"
                    className="mx-2"
                  />
                </span>
                <TextField
                  label="Primer número"
                  value={newData.primerNumeroPolicia}
                  onChange={handleChangeNewData("primerNumeroPolicia")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="number"
                />
                <TextField
                  label="Segundo número"
                  value={newData.segundoNumeroPolicia}
                  onChange={handleChangeNewData("segundoNumeroPolicia")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="number"
                />
                <TextField
                  label="Escalera"
                  value={newData.escalera}
                  onChange={handleChangeNewData("escalera")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="number"
                />
                <TextField
                  label="Planta"
                  value={newData.planta}
                  onChange={handleChangeNewData("planta")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="number"
                />
                <TextField
                  label="Puerta"
                  value={newData.puerta}
                  onChange={handleChangeNewData("puerta")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="number"
                />
                <br />
                <br />
                <Button
                  onClick={handleUpdateAddresses}
                  variant="contained"
                  className="btn btn-primary"
                >
                  Update Addresses
                </Button>
              </div>
            </Modal>
            <br />
            <br />
            <TextField
              id={`origin`}
              label="Origen"
              value={property.origin}
              onChange={handleChange("origin")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <br />
            <PropertyDashboard
              property={property}
              initialColumns={initialColumns}
            />
            <br />
            <br />
            <section>
              <label>Evolución del PipeLine del inmueble</label>
              <div className="d-flex flex-row align-items-center">
                {property?.evolution?.map((item, index) => (
                  <div key={index} className="d-flex align-items-center">
                    <span
                      className={`d-flex flex-column ${
                        index === property.evolution.length - 1
                          ? "text-warning"
                          : ""
                      }`}
                    >
                      <h6>{formatStatus(item.status)}</h6>
                      <p>{formatDate(item.date)}</p>
                    </span>
                    {index < property.evolution.length - 1 && (
                      <span className="mx-2"> {">"} </span>
                    )}
                  </div>
                ))}
              </div>
            </section>
            <br />
            <br />
            <section>
              <label>Sube las imágenes del inmueble</label>
              {renderGallery()}
            </section>
            <br />
            <br />
            <h4>Comentarios</h4>
            <div>
              {property?.comentarios.map((comment, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center my-2"
                >
                  <span>
                    <strong>{getUserNameById(comment.user)}:</strong>{" "}
                    {comment.message}
                    <br />
                    <small>
                      creado el:{" "}
                      {comment.createdAt
                        ? new Intl.DateTimeFormat("en-GB", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                          }).format(new Date(comment.createdAt))
                        : "Invalid date"}
                    </small>
                  </span>
                  {user._id === comment.user ? (
                    <Button
                      onClick={() => deleteComment(index)}
                      className="text-danger"
                    >
                      Eliminar
                    </Button>
                  ) : null}
                </div>
              ))}
            </div>
            <div className="d-flex flex-row justify-content-around">
              <TextField
                label="Añadir comentario"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                margin="normal"
                variant="outlined"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    addComment();
                  }
                }}
              />
              <Button onClick={addComment}>Añadir</Button>
            </div>
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/properties")}
          variant="outlined"
          theme={theme}
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveProperty()}
          variant="outlined"
          color="primary"
          theme={theme}
          style={{ marginRight: "20px" }}
        >
          Save property
        </Button>
        {propertyId && (
          <>
            <MuiThemeProvider>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
                theme={theme}
              >
                Delete property
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this property?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteProperty(propertyId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Property deleted successfully",
                      });
                      // postLog(
                      //   "Properties",
                      //   `delete-${propertyId}`,
                      //   user.fullName
                      // );
                      history.push("/properties");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete property.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
