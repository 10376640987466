import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { getUserById } from "../../../../api/user";
import replaceSpecialCharacters from "replace-special-characters";

export default function NotificationsPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [data, setData] = useState([]);
  const [profile, setProfile] = useState("");

  useEffect(() => {
    console.log("FETCH NOTIFICATIONS");
  }, []);

  const defaultSorted = [
    {
      dataField: "date",
      order: "desc",
    },
  ];

  return (
    <>
      <Card>
        <CardHeader title="Notifications List">
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>NOTIFICATIONS</CardBody>
      </Card>
    </>
  );
}
