/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { BreadCrumbs } from "./components/BreadCrumbs";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { IconButton, Dialog, Button } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import {
  getNotificacionesByUserId,
  updateNotificacion,
} from "../../../../api/notification";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
const today = new Date();
const currentDate = `${months[today.getMonth()]} ${today.getDate()}`;

export function SubHeader() {
  const [notificationsPopUp, setNotificationsPopUp] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const user = useSelector((state) => state.authentication.user);

  useEffect(() => {
    getNotificacionesByUserId(user._id)
      .then((res) => {
        if (res.status === 200)
          setNotifications(
            res.data.filter((notification) => !notification.seen)
          );
      })
      .catch((err) => {
        console.error("Error fetching notifications:", err);
      });
  }, [user, location.pathname]);

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useEffect(() => {
    if (notificationsPopUp) {
      const unseenNotifications = notifications.filter(
        (notification) => !notification.seen
      );
      if (unseenNotifications.length > 0) {
        unseenNotifications.forEach((notification) => {
          updateNotificacion(notification._id, { seen: true })
            .then((res) => {
              if (res.status === 200) {
                setNotifications((prevNotifications) =>
                  prevNotifications.map((prevNotification) =>
                    prevNotification._id === notification._id
                      ? { ...prevNotification, seen: true }
                      : prevNotification
                  )
                );
              }
            })
            .catch((err) => {
              console.error("Error updating notification:", err);
            });
        });
      }
    }
  }, [notificationsPopUp]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          <div className="d-flex align-items-baseline mr-5">
            <h5 className="text-dark font-weight-bold my-2 mr-5">
              <>{subheader.title}</>
              {/*<small></small>*/}
            </h5>
          </div>

          <BreadCrumbs items={subheader.breadcrumbs} />
        </div>
        {/* Toolbar */}
        <div className="d-flex align-items-center">
          <a
            href={`${process.env.PUBLIC_URL}/downloads/GuiaUsoSetClaus.pdf`}
            className="btn btn-primary btn-sm font-weight-bold"
            download="Instrucciones_de_uso.pdf"
          >
            Instrucciones de uso
          </a>
          <IconButton
            onClick={() => setNotificationsPopUp(true)}
            className="mx-2"
          >
            {notifications?.some((notification) => !notification.seen) ? (
              <NotificationsActiveIcon style={{ color: "orange" }} />
            ) : (
              <NotificationsIcon />
            )}
          </IconButton>
          <div
            /* href="#" */ className="btn btn-light btn-sm font-weight-bold"
            id="kt_dashboard_daterangepicker"
            /* data-toggle="tooltip"  */ title="Select dashboard daterange"
            data-placement="left"
            style={{ cursor: "default" }}
          >
            <span
              className="text-muted font-weight-bold mr-2"
              id="kt_dashboard_daterangepicker_title"
            >
              Today
            </span>
            <span
              className="text-primary font-weight-bold"
              id="kt_dashboard_daterangepicker_date"
            >
              {currentDate}
            </span>
          </div>
        </div>
        {/* Dialog to show notifications */}
        <Dialog
          onClose={() => setNotificationsPopUp(false)}
          aria-labelledby="simple-dialog-title"
          open={notificationsPopUp}
          PaperProps={{
            style: {
              minHeight: "40vh",
              width: "100%",
            },
          }}
        >
          <div className="p-4">
            <div className="d-flex justify-content-between">
              <h5>Notifications</h5>
            </div>
            <div>
              {notifications?.map((notification, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-between align-items-center"
                >
                  <p>{notification.message}</p>
                  <NotificationsIcon />
                </div>
              ))}
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
}
