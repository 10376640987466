import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Alert } from "react-bootstrap";

function OAuthResponse() {
  const location = useLocation();
  const [message, setMessage] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setMessage(params.get("message"));
  }, [location]);

  return (
    <Container className="mt-5">
      <h1 className="text-center">Authentication Status</h1>
      <Alert
        variant={
          message === "Successfully authenticated" ? "success" : "danger"
        }
        className="mt-4"
      >
        {message}
      </Alert>
    </Container>
  );
}

export default OAuthResponse;
