import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteEstadoPipeline,
  getEstadoPipelineById,
  postEstadoPipeline,
  updateEstadoPipeline,
} from "../../../../api/estadosPipeline";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { checkIsEmpty } from "../../../../utils/helpers";
import { useSelector } from "react-redux";
import { postLog } from "../../../../api/log";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyEstadoPipeline() {
  return {
    name: "",
    code: "",
    color: "",
    active: true,
  };
}

export default function EditEstadoPipeline() {
  const [estadoPipeline, setEstadoPipeline] = useState(
    getEmptyEstadoPipeline()
  );
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const estadoPipelineId = useParams().id;
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user);

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!estadoPipelineId) {
      disableLoadingData();
      return;
    }
    getEstadoPipelineById(estadoPipelineId)
      .then((res) => {
        if (res.status === 200) {
          setEstadoPipeline(res.data);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se ha podido obtener la estadoPipeline.",
        });
        history.push("/estados-pipeline");
      });
  }, [estadoPipelineId, disableLoadingData, history]);

  function saveEstadoPipeline() {
    if (checkIsEmpty(estadoPipeline.name))
      return alertError({
        error: null,
        customMessage: "El nombre es requerido.",
      });
    if (!estadoPipelineId) {
      postEstadoPipeline(estadoPipeline)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "EstadoPipeline creada correctamente.",
            });
            postLog(
              "EstadoPipelinees",
              `create-${estadoPipelineId}`,
              user.fullName
            );
            history.push("/estados-pipeline");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se ha podido guarda la estadoPipeline.",
          });
        });
    } else {
      updateEstadoPipeline(estadoPipelineId, estadoPipeline)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "¡Guardado!",
              customMessage: "Los cambios se han guardado correctamente.",
            });
            postLog("Categories", `update-${estadoPipelineId}`, user.fullName);
            history.push("/estados-pipeline");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "No se han podido guardar los cambios.",
          });
        });
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (event.target.value === " ") return;
    if (lang) {
      if (!estadoPipeline[element]) estadoPipeline[element] = {};
      let newText = estadoPipeline[element];
      newText[lang] = event.target.value;
      setEstadoPipeline({ ...estadoPipeline, [element]: newText });
    } else
      setEstadoPipeline({ ...estadoPipeline, [element]: event.target.value });
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Editar estadoPipeline"></CardHeader>
          <CardBody>
            <TextField
              id={`name`}
              label="Nombre"
              value={estadoPipeline.name}
              onChange={handleChange("name")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <br />
            <label>Establece el color en hexadecimal Ej: #1976d2</label>
            <TextField
              id={`color`}
              label="Color"
              value={estadoPipeline.color}
              onChange={handleChange("color")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={estadoPipeline.active}
                  onChange={() =>
                    setEstadoPipeline({
                      ...estadoPipeline,
                      active: !estadoPipeline.active,
                    })
                  }
                  name="checkActive"
                />
              }
              label="Activa"
            />
          </CardBody>
        </Card>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => history.push("/estados-pipeline")}
            variant="outlined"
            style={{ marginRight: "20px" }}
          >
            Atrás
          </Button>
          <Button
            onClick={() => saveEstadoPipeline()}
            variant="outlined"
            color="primary"
            style={{ marginRight: "20px" }}
          >
            Guardar Estado Pipeline
          </Button>
          {estadoPipelineId && (
            <>
              <MuiThemeProvider theme={theme}>
                <Button
                  onClick={() => setOpenConfirmDialog(true)}
                  variant="outlined"
                  color="secondary"
                  style={{ marginRight: "20px" }}
                >
                  Eliminar Estado Pipeline
                </Button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "auto",
                  }}
                ></div>
              </MuiThemeProvider>

              <ConfirmDialog
                title={
                  "¿Estás seguro de que quieres eliminar esta estadoPipeline?"
                }
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                  deleteEstadoPipeline(estadoPipelineId)
                    .then((res) => {
                      if (res.status === 204 || res.status === 200) {
                        alertSuccess({
                          title: "¡Eliminada!",
                          customMessage:
                            "EstadoPipeline eliminada correctamente",
                        });
                        postLog(
                          "EstadoPipelinees",
                          `delete-${estadoPipelineId}`,
                          user.fullName
                        );
                        history.push("/estados-pipeline");
                      }
                    })
                    .catch((error) => {
                      alertError({
                        error: error,
                        customMessage:
                          "No se ha podido eliminar la estadoPipeline.",
                      });
                    });
                }}
              />
            </>
          )}
        </div>
      </>
    );
}
