import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
  DateRangeColumnFilter,
  SelectColumnFilter,
  IndeterminateCheckbox,
} from "../../../components/customTable/Table";
import { alertError, alertSuccess } from "../../../../utils/logger";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EventIcon from "@material-ui/icons/Event";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import { IconButton } from "@material-ui/core";
import CommercialActivitiesPopUp from "../../../components/comercialActivities/ComercialActivitiesPopUp";
import FullCalendarView from "../../../components/comercialActivities/FullCalendarView";
import {
  getAccionesComercialesByUserId,
  updateAccionComercial,
  deleteAccionComercial,
} from "../../../../api/accionComercial";

const AccionesComerciales = () => {
  const user = useSelector((state) => state.authentication.user);
  const [commercialActions, setCommercialActions] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [currentPropertyId, setCurrentPropertyId] = useState(null);
  const [currentAction, setCurrentAction] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
  const [actionToDelete, setActionToDelete] = useState(null);
  const [listView, setListView] = useState(true);

  const fetchCommercialActions = useCallback(async () => {
    try {
      const res = await getAccionesComercialesByUserId(user._id);
      if (res.status === 200) {
        setCommercialActions(res.data);
        const urlParams = new URLSearchParams(window.location.search);
        const actionId = urlParams.get("actionId");
        if (actionId && res.data.length > 0) {
          const action = res.data.find((action) => action._id === actionId);
          if (action !== undefined) {
            setCurrentAction(action);
            setCurrentPropertyId(action?.propiedad?._id || null);
            setShowPopUp(true);
          }
        }
      }
    } catch (error) {
      alertError({ customMessage: error });
    }
  }, [user._id]);

  useEffect(() => {
    fetchCommercialActions();
  }, [fetchCommercialActions]);

  const toggleEstado = useCallback(async (accionId) => {
    setCommercialActions((currentActions) => {
      const index = currentActions.findIndex(
        (action) => action._id === accionId
      );
      if (index === -1) {
        console.error("Action not found with ID:", accionId);
        return currentActions;
      }

      const updatedActions = [...currentActions];
      updatedActions[index].estado =
        updatedActions[index].estado === "pendiente"
          ? "completado"
          : "pendiente";

      updateAccionComercial(accionId, { estado: updatedActions[index].estado })
        .then((res) => console.log("Updated action:", res.data))
        .catch((error) => console.error(error));

      return updatedActions;
    });
  }, []);

  const handleDelete = useCallback((accionId) => {
    setActionToDelete(accionId);
    setOpenConfirmDialog(1);
  }, []);

  const handleEdit = useCallback((action) => {
    setCurrentAction(action);
    setCurrentPropertyId(action?.propiedad?._id || null);
    setShowPopUp(true);
  }, []);

  const handleNew = useCallback(() => {
    setCurrentAction({});
    setCurrentPropertyId(null);
    setShowPopUp(true);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Estado",
        id: "toggle-estado",
        accessor: "estado",
        Filter: SelectColumnFilter,
        Cell: ({ row }) => (
          <input
            type="checkbox"
            style={{ cursor: "pointer" }}
            checked={row.original.estado === "completado"}
            onChange={() => toggleEstado(row.original._id)}
          />
        ),
      },
      {
        Header: "Nombre",
        accessor: "nombre",
      },
      {
        Header: "Descripción",
        accessor: "notas",
      },
      {
        Header: "Fecha de Inicio",
        accessor: "fechaInicio",
      },
      {
        Header: "Hora de inicio",
        accessor: "horaInicio",
      },
      {
        Header: "Fecha de Fin",
        accessor: "fechaFin",
      },
      {
        Header: "Hora de Fin",
        accessor: "horaFin",
      },
      {
        Header: "Propiedad",
        accessor: "propiedad.title",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Actions",
        id: "actions",
        accessor: (str) => "actions",
        Cell: ({ row }) => (
          <div className="d-flex flex-row">
            <IconButton onClick={() => handleEdit(row.original)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDelete(row.original._id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      },
    ],
    [toggleEstado, handleEdit, handleDelete]
  );

  useEffect(() => {
    if (!showPopUp) {
      fetchCommercialActions();
    }
  }, [showPopUp, fetchCommercialActions]);

  return (
    <Card>
      <CardHeader title="Lista de Acciones Comerciales">
        <CardHeaderToolbar>
          <section className="d-flex flex-row" style={{ marginRight: "50px" }}>
            <IconButton onClick={() => setListView(true)}>
              {listView ? (
                <FormatListBulletedIcon style={{ color: "orange" }} />
              ) : (
                <FormatListBulletedIcon />
              )}
            </IconButton>
            <IconButton onClick={() => setListView(false)}>
              {listView ? (
                <EventIcon />
              ) : (
                <EventIcon style={{ color: "orange" }} />
              )}
            </IconButton>
          </section>
          <button className="btn btn-primary" onClick={handleNew}>
            Añadir nueva
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {listView ? (
          Array.isArray(commercialActions) ? (
            <Table
              columns={columns}
              data={commercialActions}
              origin={"acciones-comerciales"}
            />
          ) : (
            <span>Loading data...</span>
          )
        ) : (
          <FullCalendarView
            accionesComerciales={commercialActions}
            setShow={setShowPopUp}
            setCurrentAction={setCurrentAction}
            setCurrentPropertyId={setCurrentPropertyId}
          />
        )}
        <CommercialActivitiesPopUp
          show={showPopUp}
          onHide={() => setShowPopUp(false)}
          action={currentAction}
          propertyId={currentPropertyId}
        />
      </CardBody>
      <ConfirmDialog
        title={
          "¿Estás seguro de que deseas eliminar la siguiente acción comercial?"
        }
        open={openConfirmDialog === 1}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => {
          deleteAccionComercial(actionToDelete)
            .then((res) => {
              if (res.status === 204 || res.status === 200) {
                setOpenConfirmDialog(null);
                setCommercialActions((currentActions) =>
                  currentActions.filter(
                    (action) => action._id !== actionToDelete
                  )
                );
              }
            })
            .catch((error) => {
              alertError({
                error: error,
                customMessage: "Could not remove action.",
              });
            });
        }}
      />
    </Card>
  );
};

export default AccionesComerciales;
