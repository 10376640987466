import React, { useState } from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  IconButton,
  Card,
  CardContent,
  Popover,
  Button,
  Select,
  MenuItem,
  InputBase,
} from "@material-ui/core";
import { ArrowUpward, ArrowDownward } from "@material-ui/icons";
import styles from "./Table.css";

export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <input type="checkbox" ref={resolvedRef} {...rest} />;
  }
);

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value || undefined)}
      placeholder={`Search ${count} records...`}
    />
  );
}

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = React.useMemo(() => {
    const optionsSet = new Set();
    preFilteredRows.forEach((row) => optionsSet.add(row.values[id]));
    return [...optionsSet.values()];
  }, [id, preFilteredRows]);

  return (
    <select
      value={filterValue}
      onChange={(e) => setFilter(e.target.value || undefined)}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

export function DateRangeColumnFilter({
  column: { filterValue = [], setFilter, id },
}) {
  return (
    <>
      <input
        type="date"
        value={filterValue[0] || ""}
        onChange={(e) =>
          setFilter((old = []) => [e.target.value || undefined, old[1]])
        }
        style={{ width: "70px", marginRight: "0.5rem" }}
      />
      to
      <input
        type="date"
        value={filterValue[1] || ""}
        onChange={(e) =>
          setFilter((old = []) => [old[0], e.target.value || undefined])
        }
        style={{ width: "70px", marginLeft: "0.5rem" }}
      />
    </>
  );
}

export default function Table({ columns, data, origin = null }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeColumn, setActiveColumn] = useState(null);

  const handleFilterClick = (event, column) => {
    setAnchorEl(event.currentTarget);
    setActiveColumn(column);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
    setActiveColumn(null);
  };

  const renderHeader = (column) => (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div {...column.getSortByToggleProps()} style={{ flex: "1" }}>
          {column.render("Header")}
          <span>
            {column.isSorted ? (
              column.isSortedDesc ? (
                <ArrowDownward />
              ) : (
                <ArrowUpward />
              )
            ) : (
              ""
            )}
          </span>
        </div>
        {column.canFilter && (
          <IconButton
            size="small"
            onClick={(e) => handleFilterClick(e, column.id)}
          >
            <FilterListIcon />
          </IconButton>
        )}
      </div>
      <Popover
        id={`popover-${column.id}`}
        open={activeColumn === column.id}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card>
          <CardContent>
            {column.canFilter ? column.render("Filter") : null}
          </CardContent>
        </Card>
      </Popover>
    </>
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const filterTypes = React.useMemo(
    () => ({
      between: (rows, id, filterValues) => {
        let [startDate, endDate] = filterValues;
        if (startDate && endDate) {
          startDate = new Date(startDate).getTime();
          endDate = new Date(endDate).getTime();
          return rows.filter((row) => {
            const rowDate = new Date(row.values[id]).getTime();
            return rowDate >= startDate && rowDate <= endDate;
          });
        }
        return rows;
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    selectedFlatRows,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 25 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className="table-responsive">
      <table {...getTableProps()} className={`table ${styles.table}`}>
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className={styles.th}>
                  {column.canFilter
                    ? renderHeader(column)
                    : column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps({
                  style: {
                    backgroundColor:
                      !row.original.seen && origin === "properties"
                        ? "#FFD983"
                        : "white",
                    textDecoration:
                      row.original.estado === "completado" &&
                      origin === "acciones-comerciales"
                        ? "line-through"
                        : "none",
                  },
                })}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} className="align-middle">
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={styles.pagination}>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </Button>
        <span className={styles.pageInfo}>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </Button>
        <span className={styles.gotoPage}>
          | Go to page:
          <InputBase
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            className={styles.inputPageNumber}
          />
        </span>
        <Select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          className={styles.pageSizeSelect}
        >
          {[10, 25, 50, 100].map((pageSize) => (
            <MenuItem key={pageSize} value={pageSize}>
              Show {pageSize}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
}
