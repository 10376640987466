import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";

import { updateAccionComercial } from "../../../api/accionComercial";
import { combineDateAndTime, formatDate, formatTime } from "./helpers";

export default function FullCalendarView({
  accionesComerciales,
  setShow,
  setCurrentAction,
  setCurrentPropertyId,
}) {
  const user = useSelector((state) => state.authentication.user);
  const [eventos, setEventos] = useState([]);

  const handleEventChange = (info) => {
    const accion = accionesComerciales.find(
      (accion) => accion._id === info.event.id
    );
    if (!accion) {
      console.error("Action not found with ID:", info.event.id);
      return;
    }

    const fechaInicio = new Date(info.event.start);
    const fechaFin = new Date(info.event.end);

    const updatedAccion = {
      ...accion,
      fechaInicio: formatDate(fechaInicio),
      horaInicio: formatTime(fechaInicio),
      fechaFin: formatDate(fechaFin),
      horaFin: formatTime(fechaFin),
    };

    updateAccionComercial(info.event.id, updatedAccion)
      .then((res) => console.log("Updated action:", res.data))
      .catch((error) => console.error(error));

    console.log("Updated action:", updatedAccion);
  };

  useEffect(() => {
    const eventos = accionesComerciales.map((accion) => ({
      title: accion.nombre,
      start: combineDateAndTime(accion.fechaInicio, accion.horaInicio),
      end: combineDateAndTime(accion.fechaFin, accion.horaFin),
      id: accion._id,
    }));
    setEventos(eventos);
  }, [accionesComerciales]);

  return (
    <FullCalendar
      plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      }}
      events={eventos}
      editable={true}
      selectable={true}
      eventChange={handleEventChange}
      eventClick={(info) => {
        const action = accionesComerciales.find(
          (accion) => accion._id === info.event.id
        );
        // console.log(action.propiedad._id);
        setCurrentAction(action);
        setCurrentPropertyId(action?.propiedad?._id || null);
        setShow(true);
      }}
    />
  );
}
