import React, { useState, useEffect } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Card } from "react-bootstrap";
import { Button, IconButton } from "@material-ui/core";
import { SERVER_URL } from "../../../../api/index";
import {
  getProperties,
  getPropertiesByUserId,
  updateProperty,
} from "../../../../api/property";
import { getUsers } from "../../../../api/user";
import CommercialActivitiesPopUp from "../../../components/comercialActivities/ComercialActivitiesPopUp";
import PropertyCard from "../../../components/propertycard/PropertyCard";
import FiltersCard from "../../../components/propertycard/FiltersCard";
import { getEstadosPipeline } from "../../../../api/estadosPipeline";
import EditIcon from "@material-ui/icons/Edit";
import { alertError } from "../../../../utils/logger";

export default function PipeLinePage() {
  const [columns, setColumns] = useState([]);
  const [users, setUsers] = useState([]);
  const [emptyColumns, setEmptyColumns] = useState([]);
  const [properties, setProperties] = useState([]);
  const [filters, setFilters] = useState({});
  const [showPopUp, setShowPopUp] = useState(false);
  const [currentPropertyId, setCurrentPropertyId] = useState(null);
  const [currentAction, setCurrentAction] = useState({});
  const user = useSelector((state) => state.authentication.user);

  useEffect(() => {
    if (Object.entries(filters).length === 0) {
      const updatedColumns = distributeProperties(properties);
      setColumns(updatedColumns);
      return;
    }
    const filteredProperties = properties.filter((property) => {
      if (
        filters.title &&
        !property.title.toLowerCase().includes(filters.title.toLowerCase())
      ) {
        return false;
      }
      if (
        filters.minPrice &&
        property.price[property.price.length - 1].value < filters.minPrice
      ) {
        return false;
      }
      if (
        filters.maxPrice &&
        property.price[property.price.length - 1].value > filters.maxPrice
      ) {
        return false;
      }
      if (filters.propertyType && property.type !== filters.propertyType) {
        return false;
      }
      if (filters.areaMin && property.area < filters.areaMin) {
        return false;
      }
      if (filters.areaMax && property.area > filters.areaMax) {
        return false;
      }
      if (filters.status && property.status !== filters.status) {
        return false;
      }
      if (
        filters.maxYear &&
        property.añoConstruccion > parseInt(filters.maxYear)
      ) {
        return false;
      }
      if (
        filters.minYear &&
        property.añoConstruccion < parseInt(filters.minYear)
      ) {
        return false;
      }
      if (filters.nBaños && property.nbaños !== parseInt(filters.nBaños)) {
        return false;
      }
      if (
        filters.nHabitaciones &&
        property.nhabitaciones !== parseInt(filters.nHabitaciones)
      ) {
        return false;
      }
      if (filters.nPlanta && property.nplanta !== parseInt(filters.nPlanta)) {
        return false;
      }
      if (property.active !== filters.active) return false;
      return true;
    });
    // console.log(filteredProperties);
    const updatedColumns = distributeProperties(filteredProperties);
    setColumns(updatedColumns);
  }, [filters]);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        let res;
        if (user.role === "admin") {
          res = await getProperties();
        } else {
          res = await getPropertiesByUserId(user._id);
        }

        if (res.status === 200) {
          setProperties(res.data);
        }
      } catch (error) {
        alertError({ customMessage: error });
      }
    };

    const fetchUsers = async () => {
      try {
        const res = await getUsers();
        if (res.data) {
          setUsers(res.data);
        }
      } catch (error) {
        alertError({ customMessage: error });
      }
    };

    if (user._id) {
      fetchProperties();
      fetchUsers();
    }
  }, [user._id, user.role]);

  useEffect(() => {
    if (columns.length > 0) return;
    getEstadosPipeline().then((res) => {
      if (res.status === 200) {
        const newColumns = {};
        const orderedData = res.data.sort((a, b) => a.order - b.order);
        orderedData.forEach((column) => {
          newColumns[column._id] = {
            name: column.name,
            items: [],
            color: column.color,
          };
        });
        setEmptyColumns(newColumns);
        if (properties.length > 0) {
          const updatedColumns = distributeProperties(properties, newColumns);
          setColumns(updatedColumns);
        } else setColumns(newColumns);
      }
    });
  }, [properties]);

  const distributeProperties = (
    properties,
    newColumns = { ...emptyColumns }
  ) => {
    Object.keys(newColumns).forEach((key) => {
      newColumns[key].items = [];
    });
    properties.forEach((property) => {
      if (property.status && newColumns[property.status]) {
        newColumns[property.status].items.push({
          id: property._id,
          content: property.title,
        });
      }
    });
    return newColumns;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const p = properties.find(
      (property) => property._id === result.draggableId
    );
    p.status = destination.droppableId;

    updateProperty(p._id, p)
      .then((res) => {
        if (res.status === 200) {
          console.log("Property updated");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const handleAddActivity = (propertyId, selectedAction = {}) => {
    setCurrentPropertyId(propertyId);
    setCurrentAction(selectedAction);
    setShowPopUp(true);
  };

  useEffect(() => {
    if (showPopUp === false) {
      if (user.role === "admin") {
        getProperties().then((res) => {
          if (res.status === 200) {
            setProperties(res.data);
          }
        });
      } else {
        getPropertiesByUserId(user._id)
          .then((res) => {
            if (res.status === 200) {
              setProperties(res.data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [showPopUp === false]);

  return (
    <Card>
      <CardHeader title="PipeLine">
        <CardHeaderToolbar>
          <FiltersCard setFilters={setFilters} />
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <DragDropContext onDragEnd={onDragEnd}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "flex-start",
            }}
          >
            {columns &&
              Object?.entries(columns)?.map(([columnId, column], index) => (
                <div
                  key={columnId}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "20%",
                    margin: "0 10px",
                    padding: "10px",
                    backgroundColor: column.color,
                  }}
                >
                  <h4>{column.name}</h4>
                  <Droppable droppableId={columnId}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "lightblue"
                            : "white",
                          padding: 4,
                          width: "100%",
                          minHeight: 300,
                          borderRadius: 4,
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        }}
                      >
                        {column.items.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  marginBottom: 8,
                                }}
                              >
                                <PropertyCard
                                  property={properties.find(
                                    (property) => property._id === item.id
                                  )}
                                  onAddActivity={handleAddActivity}
                                  users={users}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              ))}
          </div>
        </DragDropContext>
        <CommercialActivitiesPopUp
          show={showPopUp}
          onHide={() => setShowPopUp(false)}
          action={currentAction}
          propertyId={currentPropertyId}
        />
      </CardBody>
    </Card>
  );
}
