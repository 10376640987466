import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import {
  getEstadosPipeline,
  deleteEstadoPipeline,
  changeStatusEstadoPipeline,
  swapOrdersPipeline,
} from "../../../../api/estadosPipeline";
import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";
import { ArrowUpward } from "@material-ui/icons";
import { ArrowDownward } from "@material-ui/icons";
import { shallowEqual, useSelector } from "react-redux";
import { postLog } from "../../../../api/log";

function getData(estadoPipeline) {
  let data = [];
  for (let i = 0; i < estadoPipeline.length; ++i) {
    console.log(estadoPipeline);
    const elem = {};
    elem.name = estadoPipeline[i].name;
    elem.color = estadoPipeline[i].color;
    elem.createdAt = estadoPipeline[i].createdAt;
    elem.id = estadoPipeline[i]._id;
    elem.active = estadoPipeline[i].active;
    data = data.concat(elem);
  }
  return data;
}

export default function EstadoPipelinePage() {
  const [data, setData] = useState([]);
  const [estadoPipelineId, setEstadoPipelineId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);

  function buttonFormatter(cell) {
    const currentIndex = data.findIndex((item) => item.id === cell);
    const prevId = currentIndex > 0 ? data[currentIndex - 1].id : null;
    const nextId =
      currentIndex < data.length - 1 ? data[currentIndex + 1].id : null;

    return (
      <>
        <Tooltip title="Editar">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-estado-pipeline/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {prevId && (
          <Tooltip title="Move Up">
            <Button
              style={buttonsStyle}
              size="small"
              onClick={() => {
                swapOrdersPipeline(cell, prevId).then((res) => {
                  if (res.status === 200) {
                    const changedData = [...data];
                    const currentIndex = data.findIndex(
                      (item) => item.id === cell
                    );
                    const prevIndex = data.findIndex(
                      (item) => item.id === prevId
                    );
                    const temp = changedData[currentIndex];
                    changedData[currentIndex] = changedData[prevIndex];
                    changedData[prevIndex] = temp;
                    setData(changedData);
                  }
                });
              }}
            >
              <ArrowUpward />
            </Button>
          </Tooltip>
        )}
        {nextId && (
          <Tooltip title="Move Down">
            <Button
              style={buttonsStyle}
              size="small"
              onClick={() => {
                swapOrdersPipeline(cell, nextId).then((res) => {
                  if (res.status === 200) {
                    const changedData = [...data];
                    const currentIndex = data.findIndex(
                      (item) => item.id === cell
                    );
                    const nextIndex = data.findIndex(
                      (item) => item.id === nextId
                    );
                    const temp = changedData[currentIndex];
                    changedData[currentIndex] = changedData[nextIndex];
                    changedData[nextIndex] = temp;
                    setData(changedData);
                  }
                });
              }}
            >
              <ArrowDownward />
            </Button>
          </Tooltip>
        )}
        {user?.role === "admin" && (
          <>
            <Tooltip title="Eliminar">
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setEstadoPipelineId(cell);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </>
        )}
      </>
    );
  }

  const columns = [
    { dataField: "name", text: "Nombre", sort: true },
    { dataField: "color", text: "Color", sort: true },
    {
      dataField: "createdAt",
      text: "Fecha de creación",
      formatter: dateFormatter,
      sort: true,
    },
    { dataField: "id", text: "", formatter: buttonFormatter },
  ];

  useEffect(() => {
    getEstadosPipeline()
      .then((res) => {
        if (res.status === 200) {
          setData(getData(res.data.sort((a, b) => a.order - b.order)));
          setRefresh(false);
          postLog("estadoPipelines-list", "visualize", user.fullName);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "No se han podido obtener las estadoPipelines.",
        });
      });
  }, [refresh]);

  return (
    <>
      <Card>
        <CardHeader title="Lista de Estados Pipeline">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-estado-pipeline")}
            >
              Añadir nueva
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table data={data} columns={columns} />
          <ConfirmDialog
            title={`¿Estás seguro de que quieres ${
              estadoPipelineId?.active ? "desactivar" : "activar"
            } esta estadoPipeline?`}
            open={openConfirmDialog === 1}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              changeStatusEstadoPipeline(
                estadoPipelineId.id,
                !estadoPipelineId?.active
              )
                .then((res) => {
                  if (res.status === 200) {
                    postLog(
                      "EstadosPipeline-list",
                      "change-status",
                      user.fullName
                    );
                    alertSuccess({
                      title: `${
                        estadoPipelineId?.active
                          ? "¡Desactivada!"
                          : "¡Activada!"
                      }`,
                      customMessage: `EstadoPipeline ${
                        estadoPipelineId?.active ? "desactivada" : "activada"
                      } correctamente`,
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: `No se ha podido ${
                      estadoPipelineId?.active ? "desactivar" : "activar"
                    } esta estadoPipeline.`,
                  });
                });
            }}
          />
          <ConfirmDialog
            title={"¿Estás seguro de que quieres eliminar esta estadoPipeline?"}
            open={openConfirmDialog === 2}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteEstadoPipeline(estadoPipelineId)
                .then((res) => {
                  if (res.status === 204 || res.status === 200) {
                    alertSuccess({
                      title: "¡Eliminada!",
                      customMessage: "EstadoPipeline eliminada correctamente.",
                    });
                    postLog("EstadoPipeline", "delete", user.fullName);
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage:
                      "No se ha podido eliminar la estadoPipeline.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
