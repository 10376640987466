import { API, authClient } from "../index";

// Get all contactoes
export const getContactos = () => {
  return authClient().get(`${API}/contacto?getDisabled=true`);
};

// Get contacto by id
export const getContactoById = (id) => {
  return authClient().get(`${API}/contacto/${id}`);
};

// Delete contacto
export const deleteContacto = (id) => {
  return authClient().delete(`${API}/contacto/${id}`);
};

// Create contacto
export const postContacto = async (contacto) => {
  return authClient().post(`${API}/contacto`, contacto);
};

// Update contacto
export const updateContacto = async (id, contacto) => {
  return authClient().put(`${API}/contacto/${id}`, contacto);
};

// Change status contacto
export const changeStatusContacto = async (id, active) => {
  return authClient().put(`${API}/contacto/change-status/${id}`, { active });
};
