import { API, authClient } from "../index";

// Get all configurationes
export const getConfiguration = () => {
  return authClient().get(`${API}/configuration?getDisabled=true`);
};

// Get configuration by id
export const getConfigurationById = (id) => {
  return authClient().get(`${API}/configuration/${id}`);
};

// Delete configuration
export const deleteConfiguration = (id) => {
  return authClient().delete(`${API}/configuration/${id}`);
};

// Create configuration
export const postConfiguration = async (configuration) => {
  return authClient().post(`${API}/configuration`, configuration);
};

// Update configuration
export const updateConfiguration = async (id, configuration) => {
  return authClient().put(`${API}/configuration/${id}`, configuration);
};

// Change status configuration
export const changeStatusConfiguration = async (id, active) => {
  return authClient().put(`${API}/configuration/change-status/${id}`, {
    active,
  });
};
