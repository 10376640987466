import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Button,
  TextField,
  Input,
  InputLabel,
  FormControl,
  Divider,
  Tooltip,
  CircularProgress,
  Typography,
  Modal,
} from "@material-ui/core";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import ProgressBar from "react-bootstrap/ProgressBar";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import TodayIcon from "@material-ui/icons/Today";
import { alertError, alertSuccess } from "../../utils/logger";
import { countAdmins } from "../../api/user";
import { countProperties, getPropertiesByUserId } from "../../api/property";
import { getAccionesComercialesByUserId } from "../../api/accionComercial";
import {
  getNotificacionesByUserId,
  updateNotificacion,
} from "../../api/notification";

const determineColorBasedOnTime = (fechaInicio) => {
  const today = new Date();
  const taskDate = new Date(fechaInicio);
  const timeDiff = taskDate.getTime() - today.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  if (daysDiff <= 3) {
    return `rgba(255, ${90 + daysDiff * 40}, ${90 + daysDiff * 40})`;
  } else {
    return `rgba(${90 + (7 - daysDiff) * 30}, ${90 +
      (7 - daysDiff) * 30}, 255)`;
  }
};

export function DashboardPage() {
  const [adminsCount, setAdminsCount] = useState(0);
  const [modal, setModal] = useState(false);
  const [group, setGroup] = useState([]);
  const [propertiesCount, setPropertiesCount] = useState(0);
  const [pendingActionsCount, setPendingActionsCount] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [allActions, setAllActions] = useState([]);
  const [todaysActions, setTodaysActions] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const user = useSelector((state) => state.authentication.user);

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        countAdmins()
          .then((res) => {
            if (res.status === 200) setAdminsCount(res.data);
          })
          .catch((err) => {
            alertError({
              error: err,
              customMessage: "Error fetching data from the server",
            });
          });
        if (user.role === "admin") {
          countProperties()
            .then((res) => {
              if (res.status === 200) setPropertiesCount(res.data);
            })
            .catch((err) => {
              alertError({
                error: err,
                customMessage: "Error fetching data from the server",
              });
            });
        } else {
          getPropertiesByUserId(user._id).then((res) => {
            if (res.status === 200) setPropertiesCount(res.data.length);
          });
        }
        getAccionesComercialesByUserId(user._id)
          .then((res) => {
            if (res.status === 200) {
              const today = new Date();
              const actions = res.data.filter(
                (action) =>
                  action.fechaInicio === today.toISOString().split("T")[0]
              );
              const pendientes = res.data.filter(
                (action) => action.estado === "pendiente"
              );
              setAllActions(res.data);
              setPendingActionsCount(pendientes.length);
              setTodaysActions(actions);
            }
          })
          .catch((err) => {
            alertError({
              error: err,
              customMessage: "Error fetching data from the server",
            });
          });
        getNotificacionesByUserId(user._id)
          .then((res) => {
            if (res.status === 200) {
              //Group the notifications which between the createdAt difference is not more than 30 secs
              const groupedNotifications = [];
              let currentGroup = [];
              for (let i = 0; i < res.data.length; i++) {
                if (i === 0) {
                  currentGroup.push(res.data[i]);
                  continue;
                }
                const diff =
                  new Date(res.data[i].createdAt) -
                  new Date(res.data[i - 1].createdAt);
                if (diff < 30000) {
                  currentGroup.push(res.data[i]);
                } else {
                  groupedNotifications.push(currentGroup);
                  currentGroup = [res.data[i]];
                }

                if (i === res.data.length - 1) {
                  groupedNotifications.push(currentGroup);
                }
              }

              setNotifications(groupedNotifications);
            }
          })
          .catch((err) => {
            alertError({
              error: err,
              customMessage: "Error fetching data from the server",
            });
          });
      } catch (err) {
        alertError({
          error: err,
          customMessage: "Error fetching data from the server",
        });
      }
    };
    fetchData();
  }, []);

  const percentageOfCompletedActions = () => {
    const totalActions = allActions.length;
    const completedActions = allActions.filter(
      (action) => action.estado === "completado"
    ).length;
    return Math.round((completedActions / totalActions) * 100);
  };

  const handleDateClick = (arg) => {
    const dayActions = allActions.filter(
      (action) => action.fechaInicio === arg.dateStr
    );
    setTodaysActions(dayActions);
    setSelectedDate(arg.date);
  };

  const handleOpenNewWindow = (path) => {
    window.open(path, "_blank");
  };

  const getDate = (date) => {
    const formattedDate = new Date(date).toLocaleString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return formattedDate;
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row my-3">
          <div className="col-12 col-lg-6">
            <div className="card h-100 p-5">
              <div className="card-header">
                <TodayIcon /> What's up Today
              </div>
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: "",
                  center: "title",
                  right: "today,prev,next",
                }}
                initialView="dayGridWeek"
                fixedWeekCount={false}
                height={"auto"}
                contentHeight={"auto"}
                aspectRatio={1.35}
                dateClick={handleDateClick}
                selectable={true}
                events={allActions.map((action) => ({
                  title: action.nombre,
                  start: action.fechaInicio,
                  color: determineColorBasedOnTime(action.fechaInicio),
                }))}
                eventClick={handleDateClick}
              />
              <div className="mt-5">
                {todaysActions?.map((action, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center mb-2 p-2 border rounded"
                    style={{ position: "relative" }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: "5px",
                        backgroundColor: determineColorBasedOnTime(
                          action.fechaInicio
                        ),
                        position: "absolute",
                        left: 0,
                        top: 0,
                        bottom: 0,
                      }}
                    />
                    <div className="flex-grow-1 ms-3">
                      <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
                        {action.horaInicio} - {action.horaFin}
                      </div>
                      <div>{action.nombre}</div>
                    </div>
                    <div className="ms-auto">
                      <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={() =>
                          history.push(
                            `/acciones-comerciales?actionId=${action._id}`
                          )
                        }
                      >
                        Ver
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="card h-100 p-5">
              <div className="card-header">
                <NotificationsIcon /> Notificaciones recientes
              </div>
              {notifications.map((group, groupIndex) => (
                <div
                  key={groupIndex}
                  className="card my-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setModal(true);
                    setGroup(group);
                  }}
                >
                  <div className="card-header">
                    Nuevo grupo de propiedades asignado:{" "}
                    {getDate(group[0].createdAt)}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Modal
            open={modal}
            onClose={() => setModal(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                height: "80%",
                maxWidth: "700px",
                backgroundColor: "white",
                borderRadius: "8px",
                padding: "20px",
                outline: "none",
                overflowY: "auto",
              }}
            >
              <Typography variant="h6" id="simple-modal-title">
                Detalles del grupo de notificaciones
              </Typography>
              <ul className="list-group list-group-flush">
                {group.map((notification, index) => (
                  <li
                    key={index}
                    className="list-group-item d-flex justify-content-between align-items-center"
                    style={{
                      backgroundColor: notification.seen
                        ? "white"
                        : "rgba(255, 255, 0, 0.2)",
                    }}
                  >
                    {notification.message}
                    <button
                      className="btn btn-outline-primary btn-sm mx-2"
                      onClick={() => {
                        handleOpenNewWindow(
                          `/edit-property/${notification.propertyId}`
                        );
                        updateNotificacion(notification._id, { seen: true });
                      }}
                    >
                      Ver
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </Modal>
        </div>
        <div className="row my-3">
          <div
            className={`text-center my-2 ${
              user.role === "admin" ? "col-md-4" : "col-md-6"
            }`}
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/properties")}
          >
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  <HomeWorkIcon /> Propiedades
                </h5>
                <div className="contentDash">{propertiesCount}</div>
              </div>
            </div>
          </div>
          <div
            className={`text-center my-2 ${
              user.role === "admin" ? "col-md-4" : "col-md-6"
            }`}
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/acciones-comerciales")}
          >
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">
                  <AssignmentTurnedInIcon /> Acciones Pendientes
                </h5>
                <div className="contentDash">{pendingActionsCount}</div>
                <ProgressBar
                  now={percentageOfCompletedActions()}
                  label={`${percentageOfCompletedActions()}%`}
                />
              </div>
            </div>
          </div>
          {user.role === "admin" && (
            <div
              className="col-md-4 text-center my-2"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/admins")}
            >
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    <AssignmentIndIcon /> Administradores
                  </h5>
                  <div className="contentDash">{adminsCount}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
