import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteContacto,
  getContactoById,
  postContacto,
  updateContacto,
} from "../../../../api/contacto";
import { getProperties } from "../../../../api/property";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess, alertInfo } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { useSelector } from "react-redux";
import { postLog } from "../../../../api/log";

function getEmptyContact() {
  return {
    nombre: "",
    email: "",
    telefono: "",
    propiedad: "",
    active: true,
  };
}

export default function EditContactsPage() {
  const [contact, setContact] = useState(getEmptyContact());
  const [propiedades, setPropiedades] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const contactId = useParams().id;
  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user);

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    getProperties()
      .then((res) => {
        if (res.status === 200) {
          console.log("res.data", res.data);
          setPropiedades(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get properties.",
        });
        history.push("/contactos");
      });
    if (!contactId) {
      disableLoadingData();
      return;
    }

    getContactoById(contactId)
      .then((res) => {
        if (res.status === 200) {
          const contact = res.data;
          setContact(contact);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get contact." });
        history.push("/contactos");
      });
  }, [contactId, disableLoadingData, history]);

  function saveContact() {
    let saveContact = contact;
    if (!contactId) {
      postContacto(saveContact)
        .then((res) => {
          if (res.status === 201) {
            postLog("Contacts", `create-${contactId}`, user.fullName);
            history.push("/contactos");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save contact.",
          });
        });
    } else {
      updateContacto(contactId, saveContact)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            postLog("Contacts", `update-${contactId}`, user.fullName);
            history.push("/contactos");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    setContact({ ...contact, [element]: event.target.value });
  };

  useEffect(() => {
    console.log("contact", contact);
  }, [contact]);

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit contact"></CardHeader>
          <CardBody>
            <TextField
              id={`nombre`}
              label="Nombre"
              value={contact.nombre}
              onChange={handleChange("nombre")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              type="tel"
            />
            <TextField
              id={`telefono movil`}
              label="Teléfono móvil"
              value={contact.telefono}
              onChange={handleChange("telefono")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`email`}
              label="Email"
              value={contact.email}
              onChange={handleChange("email")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <b>¿Es el contacto de alguna propiedad?</b>
            <Autocomplete
              id="propiedad"
              options={propiedades}
              getOptionLabel={(option) => option.title}
              value={propiedades.find(
                (option) => option._id === contact.propiedad
              )}
              onChange={(event, newValue) => {
                setContact({ ...contact, propiedad: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Propiedad"
                  variant="outlined"
                  margin="normal"
                />
              )}
            />
            <br />
            <br />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/contactos")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveContact()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save contact
        </Button>
        {contactId && (
          <>
            <Button
              onClick={() => setOpenConfirmDialog(true)}
              variant="outlined"
              color="secondary"
            >
              Delete contact
            </Button>

            <ConfirmDialog
              title={"Are you sure you want to delete this contact?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteContacto(contactId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Contact deleted successfully",
                      });
                      postLog("Contacts", `delete-${contactId}`, user.fullName);
                      history.push("/contactos");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete contact.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
