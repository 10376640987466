import React, { useState, useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import { Content, ContentRoute } from "../_metronic/layout";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DashboardPage } from "./pages/DashboardPage";
import { getUnreadMessagesByUser } from "../api/message";
import { BACKOFFICE_URL } from "../api/index";
import {
  addMessage,
  addNotifications,
  getOnlineUsers,
  setNotifications,
  updateChats,
  updateCurrentChat,
} from "../redux/notificationRedux/notificationActions";
import { toAbsoluteUrl } from "../_metronic/_helpers";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import socket from "./components/socket/socket";
import AdminsPage from "./modules/ContentManager/admins/AdminsPage";
import EditAdminsPage from "./modules/ContentManager/admins/EditAdminsPage";
import TextsPage from "./modules/ContentManager/texts/TextsPage";
import EditTextsPage from "./modules/ContentManager/texts/EditTextsPage";
import LanguagesPage from "./modules/ContentManager/languages/LanguagesPage";
import EditLanguagesPage from "./modules/ContentManager/languages/EditLanguagesPage";
import PropertiesPage from "./modules/ContentManager/properties/PropertiesPage";
import EditPropertiesPage from "./modules/ContentManager/properties/EditPropertiesPage";
import ComunidadesPage from "./modules/ContentManager/comunidades/ComunidadesPage";
import EditComunidadesPage from "./modules/ContentManager/comunidades/EditComunidadesPage";
import ProvinciasPage from "./modules/ContentManager/provincias/ProvinciasPage";
import EditProvinciasPage from "./modules/ContentManager/provincias/EditProvinciasPage";
import LocalidadesPage from "./modules/ContentManager/localidades/LocalidadesPage";
import EditLocalidadesPage from "./modules/ContentManager/localidades/EditLocalidadesPage";
import LogsPage from "./modules/ContentManager/logs/LogsPage";
import NotificationsPage from "./modules/ContentManager/notifications/NotificationsPage";
import PipeLinePage from "./modules/ContentManager/pipelines/PipeLinePage";
import AccionesComerciales from "./modules/ContentManager/accionesComerciales/AccionesComerciales";
import EstadosPipeline from "./modules/ContentManager/pipelines/EstadosPipeline";
import EditEstadoPipeline from "./modules/ContentManager/pipelines/EditEstadosPipeline";
import ImportarPropiedadesPage from "./modules/ContentManager/importarPropiedades/ImportarPropiedadesPage";
import OAuthResponse from "./modules/ContentManager/admins/OAuthResponse";
import EditContactsPage from "./modules/ContentManager/contactos/EditContactsPage";
import ContactsPage from "./modules/ContentManager/contactos/ContactsPage";
import ConfigurationPage from "./modules/ContentManager/configuraciones/ConfigurationPage";
import "./BasePage.css";

export default function BasePage() {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const isAdmin = user || user?.role !== "admin";
  const history = useHistory();

  function createNotification(message) {
    // Create and show the notification
    const img = toAbsoluteUrl("/media/logo/logo.png");
    const text = `${message}`;
    const notification = new Notification("SetClaus", {
      body: text,
      icon: img,
    });
    notification.onclick = function() {
      window.open(BACKOFFICE_URL + "/chat");
    };
  }

  toastr.options = {
    positionClass: "toast-top-right",
    hideDuration: 2000,
    showDuration: 300,
    timeOut: 60000,
    closeButton: true,
    newestOnTop: true,
    showEasing: "swing",
    // hideEasing: "linear",
    showMethod: "fadeIn",
    // hideMethod: "fadeOut",
    onclick: () => {
      history.push("/dashboard");
      toastr.clear();
    },
  };

  useEffect(() => {
    if (!socket || !user) return;

    const handleNewNotification = (notification) => {
      if (user._id === notification.userId) {
        toastr.info(notification.message, "Tienes un nuevo mensaje");
        createNotification(notification.message);
      }
    };

    socket.on("connect", () => {
      console.log("Connected to server with ID", socket.id);
    });

    socket.on("notification", handleNewNotification);

    return () => {
      socket.off("notification", handleNewNotification);
    };
  }, [user]);

  return (
    <Switch>
      {/* Redirect from root URL to /dashboard. */}
      <Redirect exact from="/" to="/dashboard" />
      <ContentRoute path="/dashboard" component={DashboardPage} />

      {/* USERS */}
      {/* Administrators */}
      <ContentRoute from="/admins" component={AdminsPage} />
      <ContentRoute from="/edit-admin/:id?" component={EditAdminsPage} />
      <ContentRoute from="/oauth-response" component={OAuthResponse} />

      {/* CONTACTS */}
      <ContentRoute from="/contactos" component={ContactsPage} />
      <ContentRoute from="/edit-contacto/:id?" component={EditContactsPage} />

      {/* CONFIGURATIONS */}
      <ContentRoute
        from="/configuration"
        component={!isAdmin ? DashboardPage : ConfigurationPage}
      />

      {/* MISCELLANY */}
      {/* Languages */}
      <ContentRoute
        from="/languages"
        component={!isAdmin ? DashboardPage : LanguagesPage}
      />
      <ContentRoute
        from="/edit-language/:id?"
        component={!isAdmin ? DashboardPage : EditLanguagesPage}
      />
      {/* Texts */}
      <ContentRoute
        from="/texts"
        component={!isAdmin ? DashboardPage : TextsPage}
      />
      <ContentRoute
        from="/edit-text/:id"
        component={!isAdmin ? DashboardPage : EditTextsPage}
      />
      {/* Properties */}
      <ContentRoute from="/properties" component={PropertiesPage} />
      <ContentRoute from="/edit-property/:id?" component={EditPropertiesPage} />

      {/* sincronizar propiedades */}
      <ContentRoute
        from="/importar-propiedades"
        component={ImportarPropiedadesPage}
      />

      {/* Notifications */}
      <ContentRoute from="/notifications" component={NotificationsPage} />

      {/* PIPELINES */}
      <ContentRoute from="/pipeline" component={PipeLinePage} />
      <ContentRoute from="/estados-pipeline" component={EstadosPipeline} />
      <ContentRoute
        from="/edit-estado-pipeline/:id?"
        component={EditEstadoPipeline}
      />

      {/* Acciones comerciales */}
      <ContentRoute
        from="/acciones-comerciales"
        component={AccionesComerciales}
      />

      {/* Comunidades */}
      <ContentRoute from="/comunidades" component={ComunidadesPage} />
      <ContentRoute
        from="/edit-comunidad/:id?"
        component={EditComunidadesPage}
      />

      {/* Provincias */}
      <ContentRoute from="/provincias" component={ProvinciasPage} />
      <ContentRoute
        from="/edit-provincia/:id?"
        component={EditProvinciasPage}
      />

      {/* Localidades */}
      <ContentRoute from="/localidades" component={LocalidadesPage} />
      <ContentRoute
        from="/edit-localidad/:id?"
        component={EditLocalidadesPage}
      />

      {/* Logs */}
      <ContentRoute from="/logs" component={LogsPage} />

      <Redirect to="/error" />
    </Switch>
  );
}
