import { authClient, API } from "../index";

// Get all notificationes
export const getNotificaciones = () => {
  return authClient().get(`${API}/notification?getDisabled=true`);
};

// Get notification by id
export const getNotificacionlById = (id) => {
  return authClient().get(`${API}/notification/${id}`);
};

//Get accionesComerciales by user id
export const getNotificacionesByUserId = (id) => {
  return authClient().get(`${API}/notification/user/${id}`);
};

// Delete notification
export const deleteNotificacion = (id) => {
  return authClient().delete(`${API}/notification/${id}`);
};

// Create notification
export const postNotificacion = async (notification) => {
  return authClient().post(`${API}/notification`, notification);
};

// Update notification
export const updateNotificacion = async (id, notification) => {
  console.log(id, notification);
  return authClient().put(`${API}/notification/${id}`, notification);
};

// Change status notification
export const changeStatusNotificacion = async (id, active) => {
  return authClient().put(`${API}/notification/change-status/${id}`, {
    active,
  });
};
