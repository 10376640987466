import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Button,
  TextField,
  IconButton,
  Tooltip,
  Switch,
  FormControlLabel,
  Dialog,
  makeStyles,
  Modal,
  Grid,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Typography,
  Box,
  CardContent,
  TableContainer,
  Table as MaterialTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";
import { Table as BootstrapTable } from "react-bootstrap";
import Slider from "react-slick";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { OpenInNew } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import {
  ToggleOff,
  ToggleOn,
  PersonAdd,
  Warning,
  Check,
} from "@material-ui/icons";
import { shallowEqual, useSelector } from "react-redux";
// import { postLog } from "../../../../api/log";
// import FiltersCard from "../../../components/propertycard/FiltersCard";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { dateFormatter, buttonsStyle } from "../../../components/tables/table";
import Table, {
  DateRangeColumnFilter,
  SelectColumnFilter,
  IndeterminateCheckbox,
} from "../../../components/customTable/Table";
import {
  getPropertiesByUserId,
  getProperties,
  deleteProperty,
  getGroupCadastralReferences,
  changeStatusProperty,
  updateProperty,
} from "../../../../api/property";
import { getLocalidades } from "../../../../api/localidad";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import FiltersCard from "../../../components/propertycard/FiltersCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Properties.css";
import { SERVER_URL } from "../../../../api";
import { getUsers } from "../../../../api/user";
import LateralWindow from "./LateralWindow";
import LateralWindowUser from "./LateralWindowUsers";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import { getEstadosPipeline } from "../../../../api/estadosPipeline";
import { exportPropertiesToExcel } from "../../../../utils/exportToExcel";

const useStyles = makeStyles(() => ({
  img: {
    display: "block",
    width: "100%",
  },

  dialogContent: {
    padding: 0,
    overflow: "hidden",
  },
}));

function getData(properties) {
  let data = [];
  for (let i = 0; i < properties.length; ++i) {
    const elem = { ...properties[i], selected: false };
    elem.title =
      properties[i].title === "undefined undefined"
        ? "---------"
        : properties[i].title;
    elem.potentialDuplicates = properties[i].potentialDuplicates
      ? properties[i].potentialDuplicates
      : [];
    elem.type = properties[i].type ? properties[i].type : "---------";
    elem.createdAt = properties[i].createdAt
      ? properties[i].createdAt
      : "---------";
    elem.price = properties[i].price ? properties[i].price : [];
    elem.area = properties[i].area ? properties[i].area : 0;
    elem.nhabitaciones = properties[i].nhabitaciones
      ? properties[i].nhabitaciones
      : 0;
    elem.nbaños = properties[i].nbaños ? properties[i].nbaños : 0;
    elem.nplanta = properties[i].nplanta ? properties[i].nplanta : 0;
    elem.añoConstruccion = properties[i].añoConstruccion
      ? properties[i].añoConstruccion
      : 0;
    elem.status = properties[i].status ? properties[i].status : "---------";
    elem.image = properties[i].gallery ? properties[i].gallery : "---------";
    elem.addresses = properties[i].addresses
      ? properties[i].addresses
      : "---------";
    elem.id = properties[i]._id;
    elem.seen = properties[i].seen;
    elem.active = properties[i].active;
    elem.possibleCadastralReferences =
      properties[i].possibleCadastralReferences;
    data = data.concat(elem);
  }
  return data;
}

export default function PropertiesPage() {
  //Datos de la tabla
  const [data, setData] = useState([]);
  //Datos filtrados de la tabla
  const [filteredData, setFilteredData] = useState([]);
  //Loading mientras no tengo los datos de la tabla
  const [loading, setLoading] = useState(true);
  //Estados del pipeline
  const [estadosPipeline, setEstadosPipeline] = useState([]);
  //Id de la propiedad
  const [propertyId, setPropertyId] = useState(null);
  //Dialogo de vista previa
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  //Imagen de vista previa
  const [previewImage, setPreviewImage] = useState(null);
  //Dialogo de confirmación
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
  //Refrescar la tabla
  const [refresh, setRefresh] = useState(false);
  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(null);
  const [isLateralOpen, setIsLateralOpen] = useState(false);
  const [isLateralUsersOpen, setIsLateralUsersOpen] = useState(false);
  //Propiedad para la ventana lateral
  const [currentProperty, setCurrentProperty] = useState(null);
  //Propiedad para el popup de addresses de la propiedad
  const [addressProperty, setAddressProperty] = useState(null);
  //Propiedades duplicadas
  const [duplicates, setDuplicates] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [filters, setFilters] = useState({});
  //Cadastral References
  const [cadastralReferences, setCadastralReferences] = useState([]);
  const [selectedReference, setSelectedReference] = useState(null);
  const [openReferenceModal, setOpenReferenceModal] = useState(false);
  //Localidades
  const [localidades, setLocalidades] = useState([]);

  const history = useHistory();
  const user = useSelector((store) => store.authentication?.user, shallowEqual);

  useEffect(() => {
    if (Object.entries(filters).length === 0) {
      setFilteredData((currentData) => {
        const updatedFilteredData = currentData.map((item) => {
          const isSelected = selectedProperties.includes(item._id);
          return { ...item, selected: isSelected };
        });

        setData((data) => {
          return data.map((item) => {
            const isSelected = selectedProperties.includes(item._id);
            return { ...item, selected: isSelected };
          });
        });

        return updatedFilteredData;
      });
    }
  }, [filters, selectedProperties]);

  useEffect(() => {
    if (Object.entries(filters).length === 0) {
      setFilteredData(data.filter((item) => item.active));
      return;
    }

    const filteredProperties = data.filter((property) => {
      if (
        filters.title &&
        !property.title.toLowerCase().includes(filters.title.toLowerCase())
      ) {
        return false;
      }
      if (
        filters.minPrice &&
        property.price[property.price.length - 1].value < filters.minPrice
      ) {
        return false;
      }
      if (
        filters.maxPrice &&
        property.price[property.price.length - 1].value > filters.maxPrice
      ) {
        return false;
      }
      if (filters.propertyType && property.type !== filters.propertyType) {
        return false;
      }
      if (filters.areaMin && property.area < parseFloat(filters.areaMin)) {
        return false;
      }
      if (filters.areaMax && property.area > parseFloat(filters.areaMax)) {
        return false;
      }
      if (filters.status && property.status !== filters.status) {
        return false;
      }
      if (
        filters.maxYear &&
        property.añoConstruccion > parseInt(filters.maxYear)
      ) {
        return false;
      }
      if (
        filters.minYear &&
        property.añoConstruccion < parseInt(filters.minYear)
      ) {
        return false;
      }
      if (filters.nBaños && property.nbaños !== parseInt(filters.nBaños)) {
        return false;
      }
      if (
        filters.nHabitaciones &&
        property.nhabitaciones !== parseInt(filters.nHabitaciones)
      ) {
        return false;
      }
      if (filters.nPlanta && property.nplanta !== parseInt(filters.nPlanta)) {
        return false;
      }
      if (filters.localidad && property.localidad._id !== filters.localidad) {
        console.log(
          "Property localidad: ",
          property.localidad._id,
          typeof property.localidad
        );
        console.log(
          "Filters localidad: ",
          filters.localidad,
          typeof filters.localidad
        );
        return false;
      }
      if (property.active !== filters.active) return false;
      return true;
    });
    setFilteredData(filteredProperties);
  }, [filters, data]);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        let response;
        if (user.role === "admin") {
          response = await getProperties();
        } else {
          response = await getPropertiesByUserId(user._id);
        }

        if (response.status === 200) {
          const properties = getData(response.data);
          setData(properties);
          setFilteredData(properties.filter((item) => item.active));
          setLoading(false);
        }
      } catch (error) {
        alertError({
          error: error,
          customMessage: "Could not get properties.",
        });
      }
    };

    const fetchEstadosPipeline = async () => {
      try {
        const response = await getEstadosPipeline();
        if (response.status === 200) {
          setEstadosPipeline(response.data);
        }
      } catch (error) {
        alertError({
          error: error,
          customMessage: "Could not get estados pipeline.",
        });
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        if (response.status === 200) {
          setUsers(response.data);
        }
      } catch (error) {
        alertError({
          error: error,
          customMessage: "Could not get users.",
        });
      }
    };

    const fetchLocalidades = async () => {
      try {
        const response = await getLocalidades();
        if (response.status === 200) {
          console.log(response.data[0]);
          const filteredLocalidades = response.data.filter(
            (l) => l?.takeIntoAccount
          );
          console.log(filteredLocalidades.length);
          setLocalidades(filteredLocalidades);
        }
      } catch (error) {
        alertError({
          error: error,
          customMessage: "Could not get localidades.",
        });
      }
    };

    fetchProperties();
    fetchEstadosPipeline();
    fetchUsers();
    fetchLocalidades();
  }, [user, refresh]);

  const saveAddressProperty = async () => {
    try {
      const res = await updateProperty(addressProperty.id, addressProperty);
      if (res.status === 200) {
        setRefresh(true);
        setAddressProperty(null);
      }
    } catch (error) {
      alertError({
        error: error,
        customMessage: "Could not update property.",
      });
    }
  };

  //Render cadastral reference
  const renderCadastralReference = () => {
    if (!selectedReference) {
      return (
        <MuiCard>
          <MuiCardHeader title="Información del Catastro" />
          <CardContent>
            <Typography>
              No hay información cadastral de la dirección seleccionada
            </Typography>
          </CardContent>
        </MuiCard>
      );
    }

    return (
      <MuiCard>
        <MuiCardHeader title="Información del Catastro" />
        <CardContent>
          <Grid container spacing={3}>
            {Object.entries(selectedReference).map(([key, value]) => {
              if (
                typeof value === "object" &&
                value !== null &&
                !Array.isArray(value)
              ) {
                return (
                  <Grid item xs={12} sm={6} key={key}>
                    <Box mb={2}>
                      <Typography variant="h6" gutterBottom>
                        {key}
                      </Typography>
                      {Object.entries(value).map(([subKey, subValue]) => (
                        <Typography key={subKey}>
                          <strong>{subKey}:</strong>{" "}
                          {subValue.length > 0 ? subValue : "No disponible"}
                        </Typography>
                      ))}
                    </Box>
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} sm={6} key={key}>
                    <Typography>
                      <strong>{key}:</strong>{" "}
                      {value.length > 0 ? value : "No disponible"}
                    </Typography>
                  </Grid>
                );
              }
            })}
          </Grid>
        </CardContent>
      </MuiCard>
    );
  };

  //Find reference of address
  const findReference = (address) => {
    return cadastralReferences.find(
      (r) =>
        address.includes(r.primerNumeroPolicia) &&
        address.includes(r.segundoNumeroPolicia) &&
        address.includes(r.escalera) &&
        address.includes(r.planta) &&
        address.includes(r.puerta)
    );
  };

  //Función para abrir el dialogo del carrusel de imágenes
  function ImageCarouselDialog({ open, setOpen, images }) {
    const classes = useStyles();
    const handleClose = () => {
      setOpen(false);
    };

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        classes={{ paper: classes.dialogContent }}
      >
        <div className="slider-container">
          <Slider {...settings}>
            {images?.map((image, index) => (
              <div key={index}>
                <img
                  src={`${image}`}
                  alt={`Property Slide ${index}`}
                  className={classes.img}
                />
              </div>
            ))}
          </Slider>
        </div>
      </Dialog>
    );
  }

  //Función para formatear la imagen de la propiedad
  function imageFormatter(imageURL) {
    return imageURL && imageURL !== "" ? (
      <img
        src={imageURL[0]}
        alt="property"
        style={{ width: "50px", cursor: "pointer" }}
        onClick={() => {
          setPreviewImage(imageURL);
          setOpenPreviewDialog(true);
        }}
      />
    ) : (
      <div />
    );
  }

  //Función para añadir los botones con funcionalidades a la tabla
  function buttonFormatter(cell) {
    const elem = data.find((item) => item._id === cell);
    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={(event) => {
              if (event.ctrlKey || event.metaKey) {
                window.open("/edit-property/" + cell, "_blank");
              } else {
                history.push("/edit-property/" + cell);
              }
            }}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {user?.role === "admin" && (
          <>
            <Tooltip title={elem?.active ? "Disable" : "Enable"}>
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setPropertyId(elem);
                  setOpenConfirmDialog(1);
                }}
              >
                {elem?.active ? (
                  <ToggleOff />
                ) : (
                  <ToggleOn style={{ color: "red" }} />
                )}
              </Button>
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                style={buttonsStyle}
                size="small"
                onClick={() => {
                  setPropertyId(cell);
                  setOpenConfirmDialog(2);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </>
        )}
        <Tooltip title="More Info">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              const item = data.find((item) => item._id === cell);
              window.open(item.origin, "_blank");
            }}
          >
            <OpenInNewIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Ventana lateral">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              const item = data.find((item) => item._id === cell);
              handleLateralOpen(data.find((item) => item._id === cell));
            }}
          >
            <MenuOpenIcon />
          </Button>
        </Tooltip>
      </>
    );
  }

  //Función para formatear el estado de la propiedad
  function statusFormater(status) {
    const statusObj = estadosPipeline.find((elem) => elem._id === status);
    return statusObj ? statusObj.name : "---------";
  }

  //Función para abrir la ventana lateral
  function handleLateralOpen(property) {
    setCurrentProperty(property);
    setIsLateralOpen(true);
  }

  //Función para cerrar la ventana lateral
  function handleLateralClose() {
    setIsLateralOpen(false);
  }

  //Función para cerrar la ventana lateral de usuarios
  function handleLateralUsersClose() {
    setIsLateralUsersOpen(false);
    setSelectedProperties([]);
    setFilteredData(data.map((item) => ({ ...item, selected: false })));
  }

  //Función para seleccionar una fila de la tabla
  //CHANGES
  const handleSelectRow = (propertyId, isSelected) => {
    setSelectedProperties((prevSelected) => {
      let updatedSelectedProperties;
      if (isSelected) {
        updatedSelectedProperties = prevSelected.includes(propertyId)
          ? prevSelected
          : [...prevSelected, propertyId];
      } else {
        updatedSelectedProperties = prevSelected.filter(
          (id) => id !== propertyId
        );
      }

      // Directly update the relevant item in `data` and `filteredData`
      setData((currentData) =>
        currentData.map((item) =>
          item._id === propertyId ? { ...item, selected: isSelected } : item
        )
      );

      setFilteredData((currentData) =>
        currentData.map((item) =>
          item._id === propertyId ? { ...item, selected: isSelected } : item
        )
      );

      return updatedSelectedProperties;
    });
  };

  //Función para seleccionar todas las filas de la tabla
  const handleSelectAll = () => {
    setFilteredData((currentData) => {
      const allFilteredIds = currentData.map((item) => item._id);
      const newSelectAllChecked = !selectAllChecked;

      setSelectedProperties((prevSelected) => {
        let updatedSelectedProperties;
        if (newSelectAllChecked) {
          // Select all filtered items
          updatedSelectedProperties = [
            ...new Set([...prevSelected, ...allFilteredIds]),
          ];
        } else {
          // Deselect all filtered items
          updatedSelectedProperties = prevSelected.filter(
            (id) => !allFilteredIds.includes(id)
          );
        }

        setData((currentData) =>
          currentData.map((item) => ({
            ...item,
            selected: updatedSelectedProperties.includes(item._id),
          }))
        );
        setFilteredData((currentData) =>
          currentData.map((item) => ({
            ...item,
            selected: updatedSelectedProperties.includes(item._id),
          }))
        );

        return updatedSelectedProperties;
      });

      setSelectAllChecked(newSelectAllChecked);
      return currentData.map((item) => ({
        ...item,
        selected: newSelectAllChecked,
      }));
    });
  };

  //Función para formatear las direcciones de la propiedad
  const addressesFormatter = (addresses, propertyId) => {
    const property = data.find((item) => item.id === propertyId);
    return (
      <div
        onClick={() => {
          setOpenModal(1);
          setAddressProperty(property);
          getGroupCadastralReferences(
            property.possibleCadastralReferences
          ).then((res) => {
            if (res.status === 200) {
              setCadastralReferences(res.data);
            }
          });
        }}
        style={{ cursor: "pointer", display: "flex", flexDirection: "column" }}
      >
        {addresses.slice(0, 3).map((address, index) => (
          <span key={index}>{address.slice(0, 25)}...</span>
        ))}
        {addresses.length > 3 && <span>...</span>}
      </div>
    );
  };

  //Función para formatear las propiedades duplicadas
  function duplicateFormatter(duplicateIds) {
    const properties = data.filter((property) =>
      duplicateIds.includes(property._id)
    );

    return properties.length === 0 ? (
      <span>--------------</span>
    ) : (
      <div
        onClick={() => {
          setOpenModal(2);
          setDuplicates(properties);
        }}
      >
        {properties.map((property, index) => (
          <span key={index} style={{ cursor: "pointer" }}>
            {property.title.slice(0, 25)}...
          </span>
        ))}
      </div>
    );
  }

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className="d-flex flex-column p-1 justify-content-center">
            <span onClick={() => handleSelectAll()}>Select All</span>
          </div>
        ),
        id: "selection",
        accessor: "selected",
        disableFilters: true,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Cell: ({ row }) => (
          <div className="d-flex align-items-center justify-content-center">
            <input
              type="checkbox"
              checked={row.original.selected}
              style={{ cursor: "pointer", width: "15px", height: "15px" }}
              onChange={(e) => {
                handleSelectRow(row.original._id, e.target.checked);
              }}
            />
          </div>
        ),
      },
      {
        Header: "Duplicados",
        accessor: "potentialDuplicates",
        disableFilters: true,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Cell: ({ cell: { value } }) => duplicateFormatter(value),
      },
      {
        Header: "Nombre",
        accessor: "title",
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
      },
      {
        Header: "Galería",
        accessor: "image",
        disableFilters: true,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Cell: ({ cell: { value } }) => imageFormatter(value),
      },
      {
        Header: "Tipo de Inmueble",
        accessor: "type",
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Población",
        accessor: "localidad.fullName",
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Precio",
        accessor: "price",
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Cell: ({ value }) => {
          const sortedPrices = [...value].sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          const recentPrice = sortedPrices[0].value;
          const priceEvolution = sortedPrices
            .map(
              (price) =>
                `€${price.value} on ${new Date(
                  price.date
                ).toLocaleDateString()}`
            )
            .join("\n");
          return (
            <div title={priceEvolution} style={{ cursor: "pointer" }}>
              {recentPrice}
            </div>
          );
        },
      },
      {
        Header: "Superfície",
        accessor: "area",
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
      },
      {
        Header: "Direcciones",
        accessor: "addresses",
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Cell: ({ cell: { value }, row: { original } }) =>
          addressesFormatter(value, original.id),
      },
      {
        Header: "Estado",
        disableFilters: true,
        accessor: "status",
        filter: "includes",
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Cell: ({ cell: { value } }) => statusFormater(value),
      },
      {
        Header: "Creado el",
        accessor: "createdAt",
        disableFilters: true,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Cell: ({ cell: { value } }) => {
          const formattedDate = new Date(value).toLocaleString();
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: "",
        accessor: "id",
        disableFilters: true,
        getHeaderProps: () => {
          return {
            className: "custom-header",
          };
        },
        Cell: ({ cell: { value } }) => buttonFormatter(value),
      },
    ],
    [data]
  );

  // useEffect(() => {
  //   console.log("Selected properties: ", selectedProperties);
  // }, [selectedProperties]);

  return (
    <>
      <Card>
        <CardHeader title="Properties list">
          <CardHeaderToolbar>
            <Button
              type="button"
              className="btn btn-primary mx-2"
              onClick={() => history.push("/edit-property")}
            >
              Add new
            </Button>
            {user?.role === "admin" && (
              <Button
                variant="contained"
                className="btn btn-primary mx-2"
                disabled={selectedProperties.length === 0}
                onClick={() => {
                  setIsLateralUsersOpen(true);
                }}
              >
                Assign
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              className="btn btn-secondary mx-2"
              disabled={selectedProperties.length === 0}
              onClick={() => exportPropertiesToExcel(filteredData)}
            >
              Download Excel
            </Button>
          </CardHeaderToolbar>
          <section className="my-5">
            <FiltersCard setFilters={setFilters} localidades={localidades} />
          </section>
        </CardHeader>
        <CardBody>
          {/* <FiltersCard setFilters={setFilterOptions} /> */}
          {loading ? (
            <h2>Loading...</h2>
          ) : (
            <>
              {selectedProperties.length > 0 ? (
                <b>Propiedades seleccionadas: {selectedProperties.length}</b>
              ) : (
                <b></b>
              )}
              <Table
                data={filteredData}
                columns={columns}
                origin={"properties"}
              />
            </>
          )}
          {/* ADDRESSES MODAL */}
          <section className="d-flex justify-content-center">
            <Modal
              open={openModal === 1}
              onClose={() => {
                setOpenModal(null);
                saveAddressProperty();
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 100,
                overflowY: "auto",
              }}
            >
              <div
                style={{
                  width: "80%",
                  maxHeight: "80vh",
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "8px",
                  overflowY: "auto",
                }}
              >
                <TableContainer component={Paper}>
                  <MaterialTable>
                    <TableHead>
                      <TableRow>
                        <TableCell>Dirección</TableCell>
                        <TableCell>
                          Superfície elementos constructivos
                        </TableCell>
                        <TableCell>Año de antigüedad</TableCell>
                        <TableCell>Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {addressProperty?.addresses.map((address, index) => {
                        const reference = findReference(address);
                        return (
                          <TableRow key={index}>
                            <TableCell>{address}</TableCell>
                            <TableCell>
                              {reference
                                ? reference.superficieElementosConstructivos
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              {reference
                                ? reference.anoAntiguedadBienInmueble
                                : "N/A"}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => {
                                  const updatedAddresses = [
                                    ...addressProperty.addresses,
                                  ];
                                  updatedAddresses.splice(index, 1);

                                  const updatedPossibleReferences = cadastralReferences.filter(
                                    (r) => r !== reference
                                  );

                                  setCadastralReferences(
                                    updatedPossibleReferences
                                  );
                                  setAddressProperty({
                                    ...addressProperty,
                                    addresses: updatedAddresses,
                                    possibleCadastralReferences: updatedPossibleReferences.map(
                                      (r) => r.referenciaCatastral
                                    ),
                                  });
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setSelectedReference(reference);
                                  setOpenReferenceModal(true);
                                }}
                              >
                                <OpenInNew />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  if (reference) {
                                    const { latitud, longitud } = reference;
                                    const url = `https://www.google.com/maps/search/?api=1&query=${latitud},${longitud}`;
                                    window.open(url, "_blank");
                                  }
                                }}
                              >
                                <i className="fas fa-map-marker-alt"></i>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </MaterialTable>
                </TableContainer>
              </div>
            </Modal>
            <Modal
              open={openReferenceModal}
              onClose={() => setOpenReferenceModal(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 100,
              }}
            >
              <div
                style={{
                  maxHeight: "90vh",
                  overflowY: "auto",
                  width: "80%",
                  maxWidth: "1000px",
                  zIndex: 10,
                }}
              >
                {renderCadastralReference()}
              </div>
            </Modal>
          </section>

          {/* POTENTIAL DUPLICATES MODAL */}
          <Modal
            open={openModal === 2}
            onClose={() => setOpenModal(null)}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 100,
            }}
          >
            <div
              style={{
                width: "80%",
                maxHeight: "80vh",
                overflowY: "auto",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "8px",
              }}
            >
              <BootstrapTable striped bordered hover responsive>
                <thead className="thead-dark">
                  <tr>
                    <th>Nombre</th>
                    <th>Superficie</th>
                    <th>nBaños</th>
                    <th>nHabitaciones</th>
                    <th>nPlanta</th>
                    <th>Año Construcción</th>
                    <th>Precio</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {duplicates.map((property, index) => (
                    <tr key={index}>
                      <td>{property.title}</td>
                      <td>{property.area} m²</td>
                      <td>{property.nbaños}</td>
                      <td>{property.nhabitaciones}</td>
                      <td>{property.nplanta}</td>
                      <td>{property.añoConstruccion}</td>
                      <td>
                        {property.price[property.price.length - 1].value} €
                      </td>
                      <td>
                        <Tooltip title="Open in system new Tab">
                          <IconButton
                            onClick={() => {
                              window.open(
                                `/edit-property/${property.id}`,
                                "_blank"
                              );
                            }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Open in Idealista">
                          <IconButton
                            onClick={() => {
                              window.open(`${property.origin}`, "_blank");
                            }}
                          >
                            <OpenInBrowserIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={property?.active ? "Disable" : "Enable"}
                        >
                          <Button
                            style={buttonsStyle}
                            size="small"
                            onClick={() => {
                              setPropertyId(property);
                              setOpenConfirmDialog(1);
                            }}
                          >
                            {property?.active ? (
                              <ToggleOff />
                            ) : (
                              <ToggleOn style={{ color: "red" }} />
                            )}
                          </Button>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </BootstrapTable>
            </div>
          </Modal>
          <ConfirmDialog
            title={`Are you sure you want to ${
              propertyId?.active ? "disable" : "enable"
            } this property?`}
            open={openConfirmDialog === 1}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              changeStatusProperty(propertyId.id, !propertyId?.active)
                .then((res) => {
                  if (res.status === 200) {
                    // postLog("properties-list", "change-status", user.fullName);
                    alertSuccess({
                      title: `${propertyId?.active ? "Disabled!" : "Enabled!"}`,
                      customMessage: `Property ${
                        propertyId?.active ? "disabled" : "enabled"
                      } successfully`,
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: `Could not ${
                      propertyId?.active ? "disable" : "enable"
                    } property.`,
                  });
                });
            }}
          />
          <ConfirmDialog
            title={"Are you sure you want to remove this property?"}
            open={openConfirmDialog === 2}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteProperty(propertyId)
                .then((res) => {
                  if (res.status === 204 || res.status === 200) {
                    alertSuccess({
                      title: "Deleted!",
                      customMessage: "Property removed successfully.",
                    });
                    setRefresh(true);
                    // postLog("Properties", "delete", user.fullName);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "Could not remove property.",
                  });
                });
            }}
          />
          <ImageCarouselDialog
            open={openPreviewDialog}
            setOpen={setOpenPreviewDialog}
            images={previewImage}
          />
          <LateralWindow
            isOpen={isLateralOpen}
            onClose={handleLateralClose}
            property={currentProperty}
            users={users}
          />
          <LateralWindowUser
            isOpen={isLateralUsersOpen}
            onClose={handleLateralUsersClose}
            properties={selectedProperties}
            users={users}
          />
        </CardBody>
      </Card>
    </>
  );
}
