import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import Table, { buttonsStyle } from "../../../components/tables/table";
import { Button, Tooltip, TextField, Icon } from "@material-ui/core";
import { alertError, alertSuccess, alertInfo } from "../../../../utils/logger";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
// import { postLog } from "../../../../api/log";
import { SERVER_URL } from "../../../../api";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  updateProperty,
  getProperties,
  deleteProperty,
  changeStatusProperty,
} from "../../../../api/property";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Properties.css";

export default function LateralWindowUser({
  isOpen,
  onClose,
  properties,
  users,
}) {
  const [userAssigned, setUserAssigned] = useState(null);
  const [openUsersDialog, setOpenUsersDialog] = useState(false);
  const user = useSelector((store) => store.authentication?.user);
  const lateralRef = useRef();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    //UPDATE PROPERTIES TO ASSIGN THE SELECTED USER
    if (userAssigned) {
      const propertiesToUpdate = properties.map((property) => {
        return {
          property,
          assignedUser: userAssigned._id,
        };
      });
      propertiesToUpdate.forEach((p) => {
        updateProperty(p.property, { assignedUser: p.assignedUser })
          .then((res) => {
            console.log("PROPERTY UPDATED: ", res);
          })
          .catch((err) => {
            console.error("ERROR UPDATING PROPERTY: ", err);
          });
      });
      alertSuccess("Propiedades asignadas correctamente");
      setUserAssigned(null);
    }
  }, [onClose]);

  // useEffect(() => {
  //   console.log("PROPERTIES: ", properties);
  //   console.log("USERS: ", users);
  // }, [properties]);

  // useEffect(() => {
  //   console.log("USER ASSIGNED: ", userAssigned);
  // }, [userAssigned]);

  if (!isOpen) return null;

  return (
    <div ref={lateralRef} className={`lateral-window ${isOpen ? "open" : ""}`}>
      <Tooltip title="Cerrar" className="mx-3 p-2">
        <Button
          onClick={onClose}
          className="position-absolute top-0 end-0 m-3 custom-close-btn"
          aria-label="Close"
          style={{ zIndex: 2, boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)" }}
        >
          <CancelIcon
            style={{
              color: "#4d4d4d",
              fontSize: 32,
            }}
          />
        </Button>
      </Tooltip>
      <br />
      <section className="d-flex flex-column justify-content-center align-items center m-5 p-5">
        <h4 className="text-center">
          ¿A que usuario quieres asignar los immuebles seleccionados?
        </h4>
        <br />
        <Autocomplete
          options={users}
          getOptionLabel={(option) => option.fullName || "Usuario sin nombre"}
          value={userAssigned}
          onChange={(event, newValue) => {
            setUserAssigned(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Assign User" variant="outlined" />
          )}
          className="mx-2 p-1 rounded"
        />
      </section>
    </div>
  );
}
