import axios from "axios";

export const baseClient = () => {
  return axios.create();
};

export const authClient = () => {
  return axios.create({
    withCredentials: true,
  });
};

const PRO =
  process.env.NODE_ENV === "production" || process.env.NODE_ENV === "pro";

export const API = PRO
  ? "https://setclaus-api.owius.com:4013/api"
  : "http://localhost:4001/api";

export const SERVER_URL = PRO
  ? "https://setclaus-api.owius.com:4013"
  : "http://localhost:4001";

export const BACKOFFICE_URL = PRO
  ? "https://setclaus-backoffice.owius.com:4013/"
  : "http://localhost:3000";
