import { authClient, API } from "../index";

// Get all acciones comerciales
export const getAccionesComerciales = () => {
  return authClient().get(`${API}/accion-comercial?getDisabled=true`);
};

// Get accionComercial by id
export const getAccionComercialById = (id) => {
  return authClient().get(`${API}/accion-comercial/${id}`);
};

//Get accionesComerciales by property id
export const getAccionesComercialesByPropertyId = (id) => {
  return authClient().get(`${API}/accion-comercial/property/${id}`);
};

//Get accionesComerciales by user id
export const getAccionesComercialesByUserId = (id) => {
  return authClient().get(`${API}/accion-comercial/user/${id}`);
};

// Delete accionComercial
export const deleteAccionComercial = (id) => {
  return authClient().delete(`${API}/accion-comercial/${id}`);
};

// Create accionComercial
export const postAccionComercial = async (accionComercial) => {
  return authClient().post(`${API}/accion-comercial`, accionComercial);
};

// Update accionComercial
export const updateAccionComercial = async (id, accionComercial) => {
  return authClient().put(`${API}/accion-comercial/${id}`, accionComercial);
};

// Change status accionComercial
export const changeStatusAccionComercial = async (id, active) => {
  return authClient().put(`${API}/accion-comercial/change-status/${id}`, {
    active,
  });
};
