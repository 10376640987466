/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DescriptionIcon from "@material-ui/icons/Description";
import LanguageIcon from "@material-ui/icons/Language";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import MapIcon from "@material-ui/icons/Map";
import RoomIcon from "@material-ui/icons/Room";
import { shallowEqual, useSelector } from "react-redux";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import { PermDataSetting } from "@material-ui/icons";
import EventIcon from "@material-ui/icons/Event";
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

export function AsideMenuList({ layoutProps }) {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        <li className="menu-section">
          <h4 className="menu-text">GESTIÓN</h4>
          <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/properties",
            false
          )} ${getMenuItemActive("/edit-property", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/properties">
            <span className="menu-icon">
              <HomeWorkIcon />
            </span>
            <span className="menu-text">Properties</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/pipeline", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/pipeline">
            <span className="menu-icon">
              <DashboardIcon />
            </span>
            <span className="menu-text">PipeLine</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/acciones-comerciales",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/acciones-comerciales">
            <span className="menu-icon">
              <EventIcon />
            </span>
            <span className="menu-text">Acciones Comerciales</span>
          </NavLink>
        </li>
        {user?.role === "gestor" && (
          <li
            className={`menu-item ${getMenuItemActive(
              "/contactos",
              false
            )} ${getMenuItemActive("/edit-contacto", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/contactos">
              <span className="menu-icon">
                <ContactMailIcon />
              </span>
              <span className="menu-text">Contactos</span>
            </NavLink>
          </li>
        )}
        {user?.role === "admin" && (
          <>
            <li
              className={`menu-item ${getMenuItemActive(
                "/importar-propiedades",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/importar-propiedades">
                <span className="menu-icon">
                  <LibraryBooksIcon />
                </span>
                <span className="menu-text">Sincronizar propiedades</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/configuration",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/configuration">
                <span className="menu-icon">
                  <PermDataSetting />
                </span>
                <span className="menu-text">Configuración</span>
              </NavLink>
            </li>
            <li className="menu-section">
              <h4 className="menu-text">USUARIOS</h4>
              <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/admins",
                false
              )} ${getMenuItemActive("/edit-admin", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/admins">
                <span className="menu-icon">
                  <AssignmentIndIcon />
                </span>
                <span className="menu-text">Usuarios</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/contactos",
                false
              )} ${getMenuItemActive("/edit-contacto", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/contactos">
                <span className="menu-icon">
                  <ContactMailIcon />
                </span>
                <span className="menu-text">Contactos</span>
              </NavLink>
            </li>
            <li className="menu-section">
              <h4 className="menu-text">MISCELÁNEA</h4>
              <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/estados-pipeline",
                false
              )} ${getMenuItemActive("/estados-pipeline", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/estados-pipeline">
                <span className="menu-icon">
                  <ViewAgendaIcon />
                </span>
                <span className="menu-text">Estados Pipeline</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/comunidades",
                false
              )} ${getMenuItemActive("/edit-comunidad", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/comunidades">
                <span className="menu-icon">
                  <MapIcon />
                </span>
                <span className="menu-text">Comunidades</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/provincias",
                false
              )} ${getMenuItemActive("/edit-provincia", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/provincias">
                <span className="menu-icon">
                  <RoomIcon />
                </span>
                <span className="menu-text">Provincias</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/localidades",
                false
              )} ${getMenuItemActive("/edit-localidad", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/localidades">
                <span className="menu-icon">
                  <LocationCityIcon />
                </span>
                <span className="menu-text">Localidades</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/logs", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/logs">
                <span className="menu-icon">
                  <DescriptionIcon />
                </span>
                <span className="menu-text">Logs</span>
              </NavLink>
            </li>
          </>
        )}
        {user?.role === "admin" && (
          <>
            {/* <li
              className={`menu-item ${getMenuItemActive(
                "/languages",
                false
              )} ${getMenuItemActive("/edit-language", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/languages">
                <span className="menu-icon">
                  <LanguageIcon />
                </span>
                <span className="menu-text">Languages</span>
              </NavLink>
            </li> */}
            {/* <li
              className={`menu-item ${getMenuItemActive(
                "/texts",
                false
              )} ${getMenuItemActive("/edit-text", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/texts">
                <span className="menu-icon">
                  <DescriptionIcon />
                </span>
                <span className="menu-text">Texts</span>
              </NavLink>
            </li> */}
          </>
        )}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
