import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { getAccionesComercialesByPropertyId } from "../../../api/accionComercial";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import FlagIcon from "@material-ui/icons/Flag";
import DraftsIcon from "@material-ui/icons/Drafts";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

const activityIcons = [
  { type: "llamada", icon: <PhoneInTalkIcon /> },
  { type: "reunion", icon: <PeopleAltIcon /> },
  { type: "tarea", icon: <QueryBuilderIcon /> },
  { type: "plazo", icon: <FlagIcon /> },
  { type: "correo", icon: <DraftsIcon /> },
  { type: "comida", icon: <RestaurantIcon /> },
];

export default function PropertyCard({ property, onAddActivity, usersGroup }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [actions, setActions] = useState([]);
  const [users, setUsers] = useState([]);
  const user = useSelector((state) => state.authentication.user);

  useEffect(() => {
    if (property) {
      getAccionesComercialesByPropertyId(property._id)
        .then((res) => {
          if (res.data) {
            setActions(res.data.filter((res) => res.estado === "pendiente"));
            setUsers(usersGroup);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [property]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openAddActivity = (selectedAction = null) => {
    handleClose();
    console.log(property);
    if (selectedAction) {
      onAddActivity(property._id, selectedAction);
      return;
    }
    onAddActivity(property._id);
  };

  return (
    <Card variant="outlined">
      <CardContent className="d-flex flex-row">
        <span className="d-flex flex-column">
          <Typography variant="subtitle1">{property.title}</Typography>
          <span className="d-flex flex-row align-items-center">
            <small>{property.addresses[0]}</small>
            {property.addresses.length > 0 ? (
              <Tooltip
                title={
                  <section className="d-flex flex-row w-100">
                    <b>Posibles direcciones:</b>
                    <ul>
                      {property.addresses.map((address, index) => (
                        <li key={index}>{address}</li>
                      ))}
                    </ul>
                  </section>
                }
              >
                <IconButton>
                  <ReportProblemIcon />
                </IconButton>
              </Tooltip>
            ) : null}
          </span>
        </span>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <section style={{ maxHeight: "200px", overflow: "auto" }}>
            {actions?.map((activity, index) => (
              <section key={index}>
                <MenuItem
                  onClick={(e) => {
                    if (activity.idUsuario === user._id) {
                      openAddActivity(activity);
                    }
                  }}
                  className="d-flex justify-content-center"
                >
                  <div className="row align-items-center w-100">
                    <div className="col d-flex flex-column">
                      <span>
                        {activity.nombre.substring(0, 20) +
                          (activity.nombre.length > 20 ? "..." : "")}
                      </span>
                      <small>Finaliza el: {activity.fechaFin}</small>
                      {activity?.idUsuario != user._id ? (
                        <small>
                          <b>Creada por </b>
                          {
                            users.find((u) => u._id === activity.idUsuario)
                              ?.fullName
                          }
                        </small>
                      ) : null}
                    </div>
                    <div className="col-auto ml-auto">
                      <ListItemIcon>
                        {
                          activityIcons.find(
                            (icon) => icon.type === activity.tipo
                          )?.icon
                        }
                      </ListItemIcon>
                    </div>
                  </div>
                </MenuItem>
                <hr />
              </section>
            ))}
          </section>
          <MenuItem onClick={openAddActivity}>
            <ListItemIcon>
              <NoteAddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Programa una acción comercial" />
          </MenuItem>
        </Menu>
      </CardContent>
    </Card>
  );
}
