import { /* baseClient, */ authClient, API } from "../index";

// Get all Tasks
export const getTasks = () => {
  return authClient().get(`${API}/tasks`);
};

// Get tasks by id
export const gettasksById = (id) => {
  return authClient().get(`${API}/tasks/${id}`);
};

// Delete tasks
export const deletetasks = (id) => {
  return authClient().delete(`${API}/tasks/${id}`);
};

// Create tasks
export const posttasks = (tasks) => {
  return authClient().post(`${API}/tasks`, tasks);
};

// Update tasks
export const updatetasks = (id, tasks) => {
  return authClient().put(`${API}/tasks/${id}`, tasks);
};
