import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Tooltip,
  TextField,
  Icon,
  Modal,
  IconButton,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CancelIcon from "@material-ui/icons/Cancel";
import { alertError } from "../../../../utils/logger";
import { updateProperty } from "../../../../api/property";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Properties.css";

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
    width: "100%",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    color: "black",
  },
  option: {
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
      color: "black",
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

export default function LateralWindow({ isOpen, onClose, property, users }) {
  const [usersData, setUsersData] = useState([]);
  const [propertyData, setPropertyData] = useState();
  const [openUsersDialog, setOpenUsersDialog] = useState(false);
  const [showMoreFields, setShowMoreFields] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [newComment, setNewComment] = useState("");
  const user = useSelector((store) => store.authentication?.user);
  const lateralRef = useRef();
  const classes = useStyles();

  //Cerrar con tecla escape
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    setUsersData(users);
  }, [users]);

  useEffect(() => {
    console.log(property);
    setPropertyData(property);
  }, [property]);

  //Actualizar la propiedad cuando se cierra el modal
  useEffect(() => {
    if (
      property &&
      propertyData &&
      property._id === propertyData._id &&
      JSON.stringify(property) !== JSON.stringify(propertyData)
    ) {
      updateProperty(property._id, propertyData)
        .then((response) => {
          console.log("PROPIEDAD ACTUALIZADA");
        })
        .catch((error) => {
          alertError({
            customMessage:
              "Error al actualizar la propiedad. Inténtelo más tarde",
            error: error,
          });
        });
    }
  }, [onClose]);

  const addComment = () => {
    if (!newComment.trim()) return;
    const commentToAdd = {
      message: newComment,
      user: user._id,
      createdAt: new Date(),
    };
    setPropertyData({
      ...propertyData,
      comentarios: [...propertyData?.comentarios, commentToAdd],
    });
    setNewComment("");
  };

  const deleteComment = (commentIndex) => {
    const isConfirmed = window.confirm(
      "¿Estás seguro de que quieres eliminar el siguiente comentario?"
    );

    if (isConfirmed) {
      setPropertyData({
        ...propertyData,
        comentarios: propertyData?.comentarios.filter(
          (_, index) => index !== commentIndex
        ),
      });
    }
  };

  const getUserNameById = (userId) => {
    const user = users.find((user) => user._id === userId);
    return user ? user.fullName : "Unknown User";
  };

  const handleChange = (element) => (event) => {
    setPropertyData({ ...propertyData, [element]: event.target.value });
  };

  if (!isOpen) return null;

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 3000,
  };

  return (
    <div ref={lateralRef} className={`lateral-window ${isOpen ? "open" : ""}`}>
      <div className="position-relative">
        {propertyData?.gallery && propertyData.gallery.length !== 0 ? (
          <Slider {...settings}>
            {propertyData?.gallery?.slice(0, 5).map((image, index) => (
              <img
                src={`${image}`}
                alt={`Property Image ${index}`}
                key={index}
                style={{ maxHeight: "300px" }}
              />
            ))}
          </Slider>
        ) : (
          <div
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/dummie-house.jpg"
              )})`,
              height: "400px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              position: "relative",
            }}
          >
            <div className="d-flex align-items-center justify-content-center dummy-image-overlay">
              <span className="text-white">
                Imagen no disponible para la propiedad
              </span>
            </div>
          </div>
        )}
        <Tooltip title="Cerrar" className="mx-3 p-2">
          <Button
            onClick={onClose}
            className="position-absolute top-0 end-0 m-3 custom-close-btn"
            aria-label="Close"
            style={{ zIndex: 2, boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)" }}
          >
            <CancelIcon
              style={{
                color: "#4d4d4d",
                fontSize: 32,
              }}
            />
          </Button>
        </Tooltip>
      </div>
      <section className="p-5 lateral-window-content">
        <span className="lateral-window-chip">
          {propertyData?.assignedUser && !openUsersDialog ? (
            <section
              className="d-flex flex-row align-items-center bg-light rounded p-2"
              onClick={() => {
                setOpenUsersDialog(true);
              }}
              style={{
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
                filter: "contrast(1.2)",
              }}
            >
              <span className="mx-2 p-5">
                {usersData.find((u) => u._id === propertyData?.assignedUser)
                  ?.fullName || "Usuario sin nombre"}
              </span>
            </section>
          ) : (
            <Tooltip title="Asignar a" className="mx-3 p-2">
              <Button onClick={() => setOpenUsersDialog(true)}>
                <PersonAddIcon
                  style={{
                    fontSize: 40,
                    color: "#4d4d4d",
                  }}
                />
              </Button>
            </Tooltip>
          )}
          {openUsersDialog ? (
            <Autocomplete
              options={users}
              getOptionLabel={(option) =>
                option.fullName || "Usuario sin nombre"
              }
              value={propertyData?.assignedUser || null}
              onChange={(event, newValue) => {
                setPropertyData({
                  ...propertyData,
                  assignedUser: newValue?._id,
                });
                setOpenUsersDialog(false);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Assign User" variant="outlined" />
              )}
              className={classes.autocomplete + " mx-2 p-1 rounded"}
            />
          ) : null}
        </span>
        <h3>Propiedad {propertyData?.title}</h3>
        <span className="d-flex direction-row align-items-center"></span>
        <br />
        <TextField
          id={`title`}
          label="Título"
          value={propertyData?.title}
          onChange={handleChange("title")}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="outlined"
          required
        />
        <br />
        <Button onClick={() => setOpenModal(true)}>
          Direcciones del immueble
        </Button>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 100000,
          }}
        >
          <div className="bg-white rounded p-3 w-50 h-50 overflow-auto">
            <span className="d-flex flex-row justify-content-between">
              <h4>Direcciones:</h4>
              <Button
                onClick={() => {
                  const updatedAddresses = [...propertyData.addresses, ""];
                  setPropertyData({
                    ...propertyData,
                    addresses: updatedAddresses,
                  });
                }}
                variant="outlined"
                color="primary"
                className="rounded"
              >
                Add Address
              </Button>
            </span>
            {propertyData?.addresses.map((address, index) => (
              <div key={index} className="d-flex flex-row">
                <TextField
                  id={`address-${index}`}
                  label={`Dirección ${index + 1}`}
                  value={address}
                  onChange={(event) => {
                    const updatedAddresses = [...propertyData.addresses];
                    updatedAddresses[index] = event.target.value;
                    setPropertyData({
                      ...propertyData,
                      addresses: updatedAddresses,
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                  required
                  className="mx-2 p-2"
                />
                <br />
                <IconButton
                  onClick={() => {
                    const updatedAddresses = [...propertyData.addresses];
                    updatedAddresses.splice(index, 1);
                    setPropertyData({
                      ...propertyData,
                      addresses: updatedAddresses,
                    });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
          </div>
        </Modal>
        <br />
        <br />
        {showMoreFields ? (
          <>
            <TextField
              id={`description`}
              label="Descripción"
              value={propertyData?.description}
              onChange={handleChange("description")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              multiline
              rows={10}
              required
            />
            <br />
            <TextField
              id={`price`}
              label="Precio"
              value={propertyData?.price[propertyData?.price.length - 1].value}
              onChange={handleChange("price")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              type="number"
            />
            <br />
            <TextField
              id={`area`}
              label="Área"
              value={propertyData?.area}
              onChange={handleChange("area")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
              type="number"
            />
          </>
        ) : null}
        <Button onClick={() => setShowMoreFields(!showMoreFields)}>
          {showMoreFields ? "Ver Menos" : "Ver Más Info..."}
        </Button>
        <br />
        <br />
        <h4>Comentarios</h4>
        <div>
          {propertyData?.comentarios.map((comment, index) => (
            <div
              key={index}
              className="d-flex justify-content-between align-items-center my-2"
            >
              <span>
                <strong>{getUserNameById(comment.user)}:</strong>{" "}
                {comment.message}
                <br />
                <small>
                  creado el:{" "}
                  {comment.createdAt
                    ? new Intl.DateTimeFormat("en-GB", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      }).format(new Date(comment.createdAt))
                    : "Invalid date"}
                </small>
              </span>
              {user._id === comment.user ? (
                <Button
                  onClick={() => deleteComment(index)}
                  className="text-danger"
                >
                  Eliminar
                </Button>
              ) : null}
            </div>
          ))}
        </div>
        <div className="d-flex flex-row justify-content-around">
          <TextField
            label="Añadir comentario"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            margin="normal"
            variant="outlined"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                addComment();
              }
            }}
          />
          <Button onClick={addComment}>Añadir</Button>
        </div>
      </section>
    </div>
  );
}
