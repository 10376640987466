import React, { useState } from "react";
import {
  Button,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Switch,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: "auto",
    maxWidth: 800,
    minHeight: 60,
  },
  field: {
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1, 0),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const FiltersCard = ({ setFilters, localidades }) => {
  const [filterValues, setFilterValues] = useState({
    title: "",
    minPrice: "",
    maxPrice: "",
    propertyType: "",
    areaMin: "",
    areaMax: "",
    maxYear: "",
    minYear: "",
    nBaños: "",
    nHabitaciones: "",
    nPlanta: "",
    status: "",
    active: true,
  });

  const classes = useStyles();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilterValues((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const cleanFilters = () => {
    setFilterValues({
      title: "",
      minPrice: "",
      maxPrice: "",
      propertyType: "",
      areaMin: "",
      areaMax: "",
      maxYear: "",
      minYear: "",
      nBaños: "",
      nHabitaciones: "",
      nPlanta: "",
      status: "",
      localidad: "",
      active: true,
    });
    setFilters({});
  };

  const applyFilters = () => {
    console.log("LOCALIDAD: ", filterValues.localidad);
    setFilters(filterValues);
  };

  return (
    <form
      noValidate
      autoComplete="off"
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <Grid
        container
        spacing={2}
        className="d-flex justify-content-center align-items-center"
      >
        {/* First Row */}
        <Grid item xs={12} sm={2}>
          <TextField
            label="Título"
            name="title"
            value={filterValues.title}
            onChange={handleChange}
            fullWidth
            className={classes.field}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Precio mínimo"
            name="minPrice"
            value={filterValues.minPrice}
            onChange={handleChange}
            fullWidth
            className={classes.field}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Precio máximo"
            name="maxPrice"
            value={filterValues.maxPrice}
            onChange={handleChange}
            fullWidth
            className={classes.field}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>Property Type</InputLabel>
            <Select
              name="propertyType"
              value={filterValues.propertyType}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className={classes.field}
            >
              <MenuItem value={"Casa"}>Casa</MenuItem>
              <MenuItem value={"Adosado"}>Adosado</MenuItem>
              <MenuItem value={"Departamento"}>Apartamento</MenuItem>
              <MenuItem value={"Local"}>Local</MenuItem>
              <MenuItem value={"Terreno"}>Terreno</MenuItem>
              <MenuItem value={"Piso"}>Piso</MenuItem>
              <MenuItem value={"Ático"}>Ático</MenuItem>
              <MenuItem value={"Bajo"}>Bajo</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Área mínima"
            name="areaMin"
            value={filterValues.areaMin}
            onChange={handleChange}
            fullWidth
            className={classes.field}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Área máxima"
            name="areaMax"
            value={filterValues.areaMax}
            onChange={handleChange}
            fullWidth
            className={classes.field}
          />
        </Grid>

        {/* Second Row */}
        <Grid item xs={12} sm={2}>
          <TextField
            label="Año mínimo"
            name="minYear"
            value={filterValues.minYear}
            onChange={handleChange}
            fullWidth
            className={classes.field}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Año máximo"
            name="maxYear"
            value={filterValues.maxYear}
            onChange={handleChange}
            fullWidth
            className={classes.field}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Número de baños"
            name="nBaños"
            value={filterValues.nBaños}
            onChange={handleChange}
            fullWidth
            className={classes.field}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Número de habitaciones"
            name="nHabitaciones"
            value={filterValues.nHabitaciones}
            onChange={handleChange}
            fullWidth
            className={classes.field}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Número de plantas"
            name="nPlanta"
            value={filterValues.nPlanta}
            onChange={handleChange}
            fullWidth
            className={classes.field}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>Estado</InputLabel>
            <Select
              name="status"
              value={filterValues.status}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className={classes.field}
            >
              <MenuItem value="inPreparation">En Preparación</MenuItem>
              <MenuItem value="saleProcess">Proceso de venta</MenuItem>
              <MenuItem value="blocked">Bloqueadda</MenuItem>
              <MenuItem value="sold">Vendida</MenuItem>
              <MenuItem value="discarded">Descartada</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>Localidades</InputLabel>
            <Select
              name="localidad"
              value={filterValues?.localidad ? filterValues.localidad : ""}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className={classes.field}
            >
              {localidades?.map((localidad) => (
                <MenuItem key={localidad.fullName} value={localidad._id}>
                  {localidad.fullName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <span className="d-flex flex-row justify-content-center align-items-center">
          <span>Propiedades activas</span>
          <Switch
            checked={filterValues.active}
            name="active"
            onChange={handleChange}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </span>
        <Button
          type="button"
          color="primary"
          variant="contained"
          className={`btn btn-primary ${classes.button}`}
          onClick={applyFilters}
        >
          Apply Filters
        </Button>
        <Button
          type="button"
          color="primary"
          variant="contained"
          className={`btn btn-primary ${classes.button}`}
          onClick={cleanFilters}
        >
          Clear Filters
        </Button>
      </Grid>
    </form>
  );
};

export default FiltersCard;
