import { API, authClient } from "../index";

// Get all Estados Pipeline
export const getEstadosPipeline = () => {
  return authClient().get(`${API}/pipeline?getDisabled=true`);
};

// Get pipeline by id
export const getEstadoPipelineById = (id) => {
  return authClient().get(`${API}/pipeline/${id}`);
};

// Delete pipeline
export const deleteEstadoPipeline = (id) => {
  return authClient().delete(`${API}/pipeline/${id}`);
};

// Create pipeline
export const postEstadoPipeline = async (pipeline) => {
  return authClient().post(`${API}/pipeline`, pipeline);
};

// Update pipeline
export const updateEstadoPipeline = async (id, pipeline) => {
  return authClient().put(`${API}/pipeline/${id}`, pipeline);
};

//Swap orders pipeline
export const swapOrdersPipeline = async (id1, id2) => {
  return authClient().post(`${API}/pipeline/swap-orders`, { id1, id2 });
};

// Change status pipeline
export const changeStatusEstadoPipeline = async (id, active) => {
  return authClient().put(`${API}/pipeline/change-status/${id}`, { active });
};
