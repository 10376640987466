import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  Checkbox,
  IconButton,
  FormControl,
  DialogActions,
  FormLabel,
  Select,
  InputLabel,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import FlagIcon from "@material-ui/icons/Flag";
import DraftsIcon from "@material-ui/icons/Drafts";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { alertError, alertInfo } from "../../../utils/logger";
import { getContactos, postContacto } from "../../../api/contacto";
import { getProperties } from "../../../api/property";
import { formatDate, formatTime, combineDateAndTime } from "./helpers";
import {
  postAccionComercial,
  updateAccionComercial,
  getAccionesComercialesByUserId,
} from "../../../api/accionComercial";

const activityIcons = [
  { type: "llamada", icon: <PhoneInTalkIcon /> },
  { type: "reunion", icon: <PeopleAltIcon /> },
  { type: "tarea", icon: <QueryBuilderIcon /> },
  { type: "plazo", icon: <FlagIcon /> },
  { type: "correo", icon: <DraftsIcon /> },
  { type: "comida", icon: <RestaurantIcon /> },
];

const getEmptyAction = () => ({
  nombre: "",
  tipo: "",
  fechaInicio: "",
  fechaFin: "",
  horaInicio: "",
  horaFin: "",
  estado: "",
  notas: "",
  invitados: [],
  idUsuario: "",
  propiedad: "",
});

function getEmptyContact() {
  return {
    nombre: "",
    email: "",
    telefono: "",
    propiedad: "",
    active: true,
  };
}

const CommercialActivitiesPopUp = ({
  show,
  onHide,
  action,
  propertyId = null,
}) => {
  const [comercialAct, setComercialAct] = useState(getEmptyAction());
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(
    propertyId ? propertyId : {}
  );
  const [openModal, setOpenModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [contact, setContact] = useState(getEmptyContact());
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [events, setEvents] = useState([]);
  const [estado, setEstado] = useState(false);
  const user = useSelector((store) => store.authentication.user);

  const saveContact = () => {
    postContacto(contact)
      .then((res) => {
        if (res.status === 201) {
          // alertInfo({
          //   message: "Contacto guardado correctamente",
          // });
          setOpenModal(false);
          setContact(getEmptyContact());
          getContactos()
            .then((res) => {
              if (res.status === 200) {
                setUsers(res.data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        alertError({
          title: "Error",
          message: "Error al guardar el contacto",
        });
        console.log(error);
      });
  };

  const handleEventChange = (changeInfo) => {
    console.log("ENTRO HANDLE EVENT CHANGE", changeInfo);
    const changeInfoId = changeInfo.event.id;
    const updatedEvents = events.map((event) => {
      if (changeInfoId === event.id || !event.id) {
        return {
          ...event,
          start: changeInfo.event.start,
          end: changeInfo.event.end,
        };
      }
      const startDateFormatted = formatDate(changeInfo.event.start);
      const startTimeFormatted = formatTime(changeInfo.event.start);
      const endDateFormatted = formatDate(changeInfo.event.end);
      const endTimeFormatted = formatTime(changeInfo.event.end);

      setStartDate(startDateFormatted);
      setStartTime(startTimeFormatted);
      setEndDate(endDateFormatted);
      setEndTime(endTimeFormatted);
      return event;
    });
    setEvents(updatedEvents);
  };

  const handleDateSelect = (selectInfo) => {
    console.log("ENTRO HANDLE DATE SELECT", selectInfo);

    const start = new Date(selectInfo.startStr);
    const end = new Date(selectInfo.endStr);

    const startDateFormatted = formatDate(start);
    const startTimeFormatted = formatTime(start);
    const endDateFormatted = formatDate(end);
    const endTimeFormatted = formatTime(end);

    setStartDate(startDateFormatted);
    setStartTime(startTimeFormatted);
    setEndDate(endDateFormatted);
    setEndTime(endTimeFormatted);

    const existingEvent = events.find((event) => !event.id);

    if (existingEvent) {
      existingEvent.title = comercialAct.nombre || "Actividad sin nombre";
      existingEvent.start = start;
      existingEvent.end = end;
      setEvents([...events]);
    } else {
      const newEvent = {
        title: comercialAct.nombre || "Actividad sin nombre",
        start: start,
        end: end,
      };
      setEvents([...events, newEvent]);
    }
  };

  const handleSubmit = () => {
    const comercialActCopy = { ...comercialAct };
    comercialActCopy.fechaInicio = startDate;
    comercialActCopy.fechaFin = endDate;
    comercialActCopy.horaInicio = startTime;
    comercialActCopy.horaFin = endTime;
    comercialActCopy.idUsuario = user._id;
    comercialActCopy.propiedad = selectedProperty?._id;
    comercialActCopy.estado = estado ? "completada" : "pendiente";

    if (
      !comercialActCopy.estado ||
      !comercialActCopy.tipo ||
      !comercialActCopy.nombre ||
      !comercialActCopy.fechaInicio ||
      !comercialActCopy.fechaFin ||
      !comercialActCopy.horaInicio ||
      !comercialActCopy.horaFin ||
      !comercialActCopy.idUsuario
    ) {
      alertInfo({
        message: "Por favor, rellene todos los campos",
      });
      return;
    }

    if (comercialAct._id) {
      updateAccionComercial(comercialAct._id, comercialActCopy)
        .then((res) => {
          if (res.status === 200) {
            onHide();
          }
        })
        .catch((error) => {
          onHide();
          alertError({
            title: "Error",
            message: "Error al guardar la actividad",
          });
          console.log(error);
        });
      return;
    }
    postAccionComercial(comercialActCopy)
      .then((res) => {
        if (res.status === 201) {
          onHide();
        }
      })
      .catch((error) => {
        alertError({
          title: "Error",
          message: "Error al guardar la actividad",
        });
        console.log(error);
      });
  };

  const updateOrCreateEvent = () => {
    const startDateTime = combineDateAndTime(startDate, startTime);
    const endDateTime = combineDateAndTime(endDate, endTime);

    let existingEventIndex;

    if (action._id) {
      existingEventIndex = events.findIndex((event) => event.id === action._id);
    } else {
      existingEventIndex = events.findIndex((event) => !event.id);
    }

    if (existingEventIndex !== -1) {
      const updatedEvents = [...events];
      updatedEvents[existingEventIndex] = {
        ...updatedEvents[existingEventIndex],
        start: startDateTime,
        end: endDateTime,
      };
      setEvents(updatedEvents);
    } else {
      const newEvent = {
        title: comercialAct.nombre || "Actividad sin nombre",
        start: startDateTime,
        end: endDateTime,
      };
      setEvents([...events, newEvent]);
    }
  };

  const handleChangeContact = (element) => (event) => {
    setContact({ ...contact, [element]: event.target.value });
  };

  useEffect(() => {
    getContactos()
      .then((res) => {
        if (res.status === 200) {
          setUsers(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (Object.keys(action).length !== 0 && action._id) {
      const invitadosObj = users.filter((user) =>
        action.invitados.includes(user._id)
      );
      setComercialAct({ ...action, invitados: invitadosObj });
      setStartDate(action.fechaInicio);
      setStartTime(action.horaInicio);
      setEndDate(action.fechaFin);
      setEndTime(action.horaFin);
      setEstado(action.estado === "completada" ? true : false);
    } else {
      setComercialAct(getEmptyAction());
      setStartDate("");
      setStartTime("");
      setEndDate("");
      setEndTime("");
      setEstado(false);
    }
    getAccionesComercialesByUserId(user._id)
      .then((res) => {
        if (res.status === 200) {
          setEvents(
            res.data.map((act) => ({
              title: act.nombre,
              start: combineDateAndTime(act.fechaInicio, act.horaInicio),
              end: combineDateAndTime(act.fechaFin, act.horaFin),
              editable: act._id === action._id,
              id: act._id,
            }))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
    getProperties()
      .then((res) => {
        if (res.status === 200) {
          setProperties(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [action]);

  useEffect(() => {
    console.log("events", events);
  }, [events]);

  useEffect(() => {
    updateOrCreateEvent();
  }, [startDate, startTime, endDate, endTime]);

  useEffect(() => {
    console.log(propertyId);
    setSelectedProperty(properties.find((p) => p._id === propertyId));
  }, [propertyId]);

  useEffect(() => {
    console.log("CONTACT: ", contact);
  }, [contact]);

  return (
    <Dialog
      open={show}
      // onClose={onHide}
      fullWidth={true}
      maxWidth={"lg"}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          minHeight: "80vh",
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle id="form-dialog-title">
        Programar una acción para {selectedProperty?.title}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className="overflow-hidden">
          <Grid item xs={12} md={6}>
            <TextField
              margin="normal"
              id="activity-name"
              label="Nombre de la Actividad"
              fullWidth
              value={comercialAct?.nombre}
              onChange={(e) =>
                setComercialAct({ ...comercialAct, nombre: e.target.value })
              }
            />
            <br />
            <FormControl fullWidth margin="normal" className="d-flex flex-row">
              {activityIcons.map((activity) => (
                <IconButton
                  key={activity.type}
                  onClick={() =>
                    setComercialAct({ ...comercialAct, tipo: activity.type })
                  }
                  color={
                    comercialAct.tipo === activity.type ? "primary" : "default"
                  }
                  title={activity.type}
                >
                  {activity.icon}
                </IconButton>
              ))}
            </FormControl>
            <br />
            <Autocomplete
              id="property"
              options={properties}
              getOptionLabel={(option) => option.title}
              value={selectedProperty}
              onChange={(event, newValue) => {
                setSelectedProperty(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Propiedad"
                  variant="outlined"
                  margin="normal"
                />
              )}
              className="w-100"
              margin="dense"
              required
            />
            <br />
            <br />
            <section className="d-flex flex-row justify-content-center align-items-center">
              <Autocomplete
                options={users}
                getOptionLabel={(option) =>
                  option.nombre || "Usuario sin nombre"
                }
                value={comercialAct.invitados || []}
                onChange={(event, newValue) => {
                  setComercialAct({
                    ...comercialAct,
                    invitados: newValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Invitados" variant="outlined" />
                )}
                className="rounded w-100"
                multiple
                disableCloseOnSelect
              />
              <Button
                type="button"
                className="btn btn-primary p-2 ml-2"
                onClick={() => setOpenModal(true)}
              >
                Añadir contacto
              </Button>
            </section>
            <br />
            {/* DIALOGO PARA LOS INVITADOS */}
            <Dialog open={openModal} onClose={() => setOpenModal(false)}>
              <DialogTitle>Añadir contacto</DialogTitle>
              <DialogContent>
                <TextField
                  id={`nombre`}
                  label="Nombre"
                  value={contact.nombre}
                  onChange={handleChangeContact("nombre")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                  required
                  type="tel"
                />
                <TextField
                  id={`telefono movil`}
                  label="Teléfono móvil"
                  value={contact.telefono}
                  onChange={handleChangeContact("telefono")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                  required
                />
                <TextField
                  id={`email`}
                  label="Email"
                  value={contact.email}
                  onChange={handleChangeContact("email")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin="normal"
                  variant="outlined"
                  required
                />
                <b>¿Es el contacto de alguna propiedad?</b>
                <Autocomplete
                  id="propiedad"
                  options={properties}
                  getOptionLabel={(option) => option.title}
                  value={properties.find(
                    (option) => option._id === contact.propiedad
                  )}
                  onChange={(event, newValue) => {
                    setContact({ ...contact, propiedad: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Propiedad"
                      variant="outlined"
                      margin="normal"
                    />
                  )}
                />
                <br />
                <br />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
                <Button onClick={() => saveContact()}>Guardar</Button>
              </DialogActions>
            </Dialog>
            <br />
            <FormControl className="d-flex flex-row justify-content-around">
              <TextField
                margin="normal"
                id="date"
                label="Fecha de inicio"
                type="date"
                value={startDate}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                className="mx-2"
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
              <TextField
                margin="normal"
                id="time"
                label="Hora de inicio"
                type="time"
                value={startTime}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                className="mx-2"
                onChange={(e) => {
                  setStartTime(e.target.value);
                }}
              />
              <TextField
                margin="normal"
                id="endDate"
                label="Fecha de fin"
                type="date"
                value={endDate}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                className="mx-2"
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
              <TextField
                margin="normal"
                id="endTime"
                label="Hora de fin"
                type="time"
                value={endTime}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                className="mx-2"
                onChange={(e) => {
                  setEndTime(e.target.value);
                }}
              />
            </FormControl>
            <TextField
              margin="normal"
              id="notes"
              label="Notas"
              multiline
              rows={4}
              fullWidth
              value={comercialAct?.notas}
              onChange={(e) =>
                setComercialAct({ ...comercialAct, notas: e.target.value })
              }
            />
            <br />
            <DialogActions>
              <FormLabel className="d-flex flex-row justify-content-center align-items-center">
                Marcar como completado
                <Checkbox
                  color="primary"
                  onClick={(e) => setEstado(!estado)}
                  checked={estado}
                />
              </FormLabel>
              <Button onClick={onHide} color="primary">
                Cancelar
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Guardar
              </Button>
            </DialogActions>
          </Grid>
          <Grid item xs={12} md={6} className="overflow-auto">
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="timeGridDay"
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              firstDay={1}
              initialDate={comercialAct.fechaInicio || new Date()}
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              select={handleDateSelect}
              eventChange={handleEventChange}
              events={events}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CommercialActivitiesPopUp;
